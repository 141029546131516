import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
  Container,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setItem } from '../../../utils/storage';
import { dataAttuale } from '../../../utils/dataAttuale';

function FiltriUtente({
  filtri,
  setFiltri,
  onChange,
  getUtenti,
}) {
  const initialState = {
    data_registrazione_from: '',
    data_registrazione_to: '',
    desc_cognome: '',
    desc_nome: '',
    fk_codi_tipo_connettore: null,
    identificativo: '',
    profondita_storica: false,
    trusted: null,
  };

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="desc_nome">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Nome
                </Form.Label>
                <Form.Control
                  placeholder="Inserisci il nome"
                  onChange={onChange}
                  value={filtri.desc_nome}
                />
              </Form.Group>

              <Form.Group as={Col} lg controlId="desc_cognome">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Cognome
                </Form.Label>
                <Form.Control
                  placeholder="Inserisci il cognome"
                  onChange={onChange}
                  value={filtri.desc_cognome}
                />
              </Form.Group>

              <Form.Group as={Col} lg controlId="identificativo">
                <Form.Label style={{ fontWeight: 'bold' }}>Identificativo</Form.Label>
                <Form.Control placeholder="Inserisci il C.F. o il codice connettore" onChange={onChange} value={filtri.identificativo} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_registrazione_from" style={{ fontWeight: 'bold' }}>
                  Data registrazione dal
                </Form.Label>
                <input
                  type="date"
                  id="data_registrazione_from"
                  name="dataRegistrazioneFrom"
                  min="2021-01-01"
                  max={dataAttuale()}
                  value={filtri.data_registrazione_from}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_registrazione_to" style={{ fontWeight: 'bold' }}>
                  Data registrazione al
                </Form.Label>
                <input
                  type="date"
                  id="data_registrazione_to"
                  name="dataRegistrazioneTo"
                  min={filtri.data_registrazione_from || '2021-01-01'}
                  max={dataAttuale()}
                  value={filtri.data_registrazione_to}
                  className="form-control"
                  onChange={onChange}
                />
              </Form.Group>
            </Row>
            {/* <Row className="mb-3">
              <Form.Group as={Col} className="col-lg-6" controlId="profondita_storica">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Visualizza profondità storica
                </Form.Label>
                <Form.Select
                  onChange={onChange}
                  value={filtri.profondita_storica}
                >
                  <option value="true">Sì</option>
                  <option value="false">No</option>
                </Form.Select>
              </Form.Group>
            </Row> */}
            <Container className="p-0 justify-content-end d-flex gap-1">
              <Button
                variant="outline-dark"
                onClick={() => {
                  setFiltri(initialState);
                }}
              >
                <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eraser']} style={{ marginRight: '2px' }} />
                PULISCI CAMPI
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setItem('filtri', JSON.stringify(filtri));
                  getUtenti(0, 10, 1);
                }}
              >
                <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
                CERCA
              </Button>
            </Container>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriUtente;
