import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function TitlePage({
  title,
  subtitle,
  showIndietro,
  functionIndietro,
  showRefresh,
  functionRefresh,
  showAdd,
  functionAdd,
  showSave,
  functionSave,
  disabledSave = false,
  showPreview,
  functionPreview,
  showButtonExport,
  buttonExport,
  showCompleta,
  functionCompleta,
  showAvanti,
  functionAvanti,
  showAvvia,
  functionAvvia,
  showDelete,
  functionDelete,
  showModify,
  functionModify,
  showConsolida,
  functionConsolida,
  titleConsolida,
  showGeneral,
  functionGeneral,
  iconGeneral,
  variantGeneral,
  titleGeneral,
  styleGeneral,
  showView,
  functionView,
}) {
  return (
    <div className="w-100">
      <div className="mb-3">
        {title
          ? (
            <h1>
              {' '}
              {title}
              {' '}
            </h1>
          )
          : null}
        {subtitle ? (
          <h2 className="font-weight-normal lead">
            {' '}
            {subtitle}
            {' '}
          </h2>
        ) : null}
      </div>
      <Row className="position-relative mb-2">
        <Col className="col-md-4">
          {showIndietro ? (
            <Button variant="grey" className=" float-left" onClick={functionIndietro}>
              {' '}
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'arrow-left']} />
              {' '}
              INDIETRO
              {' '}
            </Button>
          ) : null }
        </Col>
        <Col>
          <Row className="float-end">
            {showRefresh
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="primary" onClick={functionRefresh}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'sync-alt']} />
                    {' '}
                    AGGIORNA
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showAdd
              ? (
                <Col className="col-lg-auto text-end">

                  <Button variant="primary" onClick={functionAdd}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
                    {' '}
                    AGGIUNGI
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showSave
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="primary" onClick={functionSave} disabled={disabledSave}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'save']} />
                    {' '}
                    SALVA
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showPreview
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="primary" onClick={functionPreview}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'file-alt']} />
                    {' '}
                    PREVIEW
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showButtonExport
              ? (
                <Col className="col-lg-auto text-end">
                  {buttonExport}
                </Col>
              )
              : null}
            {showCompleta
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="primary" onClick={functionCompleta}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'save']} />
                    {' '}
                    COMPLETA
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showAvanti
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="primary" onClick={functionAvanti}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'arrow-right']} />
                    {' '}
                    AVANTI
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showDelete
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="danger" onClick={functionDelete}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'trash-alt']} />
                    {' '}
                    ELIMINA
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showModify
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="primary" onClick={functionModify}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'pen-to-square']} />
                    {' '}
                    MODIFICA
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showView
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="outline-primary" onClick={functionView}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eye']} />
                    {' '}
                    VISUALIZZA
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showAvvia
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="success" onClick={functionAvvia}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'play']} />
                    {' '}
                    AVVIA
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showConsolida
              ? (
                <Col className="col-lg-auto text-end">
                  <Button variant="primary" onClick={functionConsolida}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'file-pen']} />
                    {' '}
                    {titleConsolida}
                    {' '}
                  </Button>
                </Col>
              )
              : null }
            {showGeneral
              ? (
                <Col className="col-lg-auto text-end">
                  <Button style={styleGeneral ?? {}} variant={variantGeneral ?? 'info'} onClick={functionGeneral}>
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', iconGeneral ?? 'info']} />
                    {' '}
                    {titleGeneral ?? 'Info'}
                    {' '}
                  </Button>
                </Col>
              )
              : null }
          </Row>
        </Col>
      </Row>
    </div>
  );
}
