import { Container, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import getVariantColor from '../../../../utils/getVariantColor';

const fieldsCompetenzeDemoAutoAssessment = (
  optionsGrading,
) => {
  // function per settare il valore corretto selezionato nella Select
  const getSelectValue = (row, type) => {
    const gradingSelected = row[type];
    return optionsGrading?.filter(
      (el) => el.codi_valore === gradingSelected,
    )?.[0];
  };

  // Function per settare le opzioni di grading corrette a seconda della competenza
  const optionsGradingByType = (type) => {
    const newList = optionsGrading.filter((grading) => grading.codi_tipo_competenza === type);
    return newList;
  };

  return (
    [
      {
        dataField: 'comp_desc_codice',
        text: 'Codice',
        classes: 'column-data',
        headerStyle: () => ({ width: '10%' }),
      },
      {
        dataField: 'comp_desc_descrizione',
        text: 'Competenza',
        classes: 'column-data',
        headerStyle: () => ({ width: '45%' }),
      },
      {
        dataField: 'codi_grading_atteso',
        text: 'Grading Atteso',
        classes: 'column-data',
        headerStyle: () => ({ width: '15%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Select
              isDisabled
              styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
              className="w-100"
              id="codi_grading_atteso"
              name="codi_grading_atteso"
              options={optionsGradingByType(row.comp_tipo_competenza)}
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                      getVariantColor((
                        option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                      ) * 100)
                    }
                    label={option.codi_valore}
                    now={
                      (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                      * 100
                    }
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getSelectValue(row, 'codi_grading_atteso')}
            />
          </div>
        ),
      },
      {
        dataField: 'codi_grading_autovalutazione',
        text: 'Autovalutazione',
        classes: 'column-data',
        headerStyle: () => ({ width: '15%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Select
              className="w-100"
              id="codi_grading_assegnato"
              name="codi_grading_assegnato"
              options={optionsGradingByType(row.comp_tipo_competenza)}
              placeholder="Associa un grading"
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                      getVariantColor((
                        option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                      ) * 100)
                    }
                    label={option.codi_valore}
                    now={
                      (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                      * 100
                    }
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getSelectValue(row, 'codi_grading_assegnato')}
              // onChange={(selectedOption) => { onChange(selectedOption, row); }}
            />
          </div>
        ),
      },
    ]
  );
};

export default fieldsCompetenzeDemoAutoAssessment;
