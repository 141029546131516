import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'react-bootstrap';

function ExportButtonExcel({
  dropdown,
  functionExport,
}) {
  if (dropdown) {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle variant="success" className="text-white">
            <span>
              <FontAwesomeIcon aria-hidden="true" icon="file-download" />
              {' '}
              ESPORTA
              {' '}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => functionExport('excel')}>Profili Professionali</Dropdown.Item>
            <Dropdown.Item onClick={() => functionExport('matrice-excel')}>Matrice dei Profili Professionali</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
  return (
    <Button variant="success" className="text-white" onClick={() => functionExport()}>
      <FontAwesomeIcon aria-hidden="true" icon="file-download" />
      {' '}
      ESPORTA
      {' '}
    </Button>
  );
}

export default ExportButtonExcel;
