import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Container, Form, ProgressBar } from 'react-bootstrap';
import themeColors from '../../../../utils/contants/theme-colors';
import getVariantColor from '../../../../utils/getVariantColor';

const fieldsCompetenze = (
  onDeleteCompetenza,
  optionsGrading,
  onChange,
  competenzeListSelected,
  isDisabled,
) => {
  // function per settare il valore corretto selezionato nella Select
  const getSelectValue = (row) => {
    const gradingSelected = competenzeListSelected.filter(
      (el) => el.fk_sequ_competenza === row.fk_sequ_competenza,
    )[0]?.grading;
    return optionsGrading?.filter(
      (el) => el.codi_valore === gradingSelected,
    )?.[0];
  };

  // function per determinare la lista di grading da poter associare ad una competenza
  const getOptionsGrading = (competenza, listAllGrading) => {
    if (competenza.provenienza === 'F' || competenza.provenienza === 'D') {
      return listAllGrading.filter((el) => el.codi_valore > competenza.grading_base);
    }
    return listAllGrading;
  };

  if (isDisabled) {
    return (
      [
        {
          dataField: 'desc_codice',
          text: 'Codice',
          sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '20%' }),
        },
        {
          dataField: 'desc_titolo',
          text: 'Competenza',
          sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '20%' }),
        },
        {
          dataField: 'grading',
          text: 'Grading',
          classes: 'column-data',
          headerStyle: () => ({ width: '50%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                className="w-100"
                id="grading"
                name="grading"
                options={getOptionsGrading(row, optionsGrading)}
                placeholder="Associa un grading"
                getOptionLabel={(option) => (
                  <Container className="p-0">
                    {option.desc_titolo}
                    <ProgressBar
                      variant={getVariantColor((option.codi_valore / optionsGrading.length) * 100)}
                      label={option.codi_valore}
                      now={(option.codi_valore / optionsGrading.length) * 100}
                    />
                  </Container>
                )}
                getOptionValue={(option) => option.codi_valore}
                value={getSelectValue(row)}
                onChange={(selectedOption, config) => { onChange(selectedOption, config, row); }}
                isDisabled
              />
            </div>
          ),
        },
      ]
    );
  } return (
    [
      {
        dataField: 'desc_codice',
        text: 'Codice',
        sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '20%' }),
      },
      {
        dataField: 'desc_titolo',
        text: 'Competenza',
        sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '20%' }),
      },
      {
        dataField: 'grading_view',
        text: 'Grading',
        classes: 'column-data',
        headerStyle: () => ({ width: '50%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Select
              className="w-100"
              id="grading"
              name="grading"
              options={getOptionsGrading(row, optionsGrading)}
              placeholder="Associa un grading"
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={getVariantColor((option.codi_valore / optionsGrading.length) * 100)}
                    label={option.codi_valore}
                    now={(option.codi_valore / optionsGrading.length) * 100}
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getSelectValue(row)}
              onChange={(selectedOption, config) => { onChange(selectedOption, config, row); }}
            />
          </div>
        ),
      },
      {
        dataField: 'df1',
        isDummyField: true,
        text: '',
        headerStyle: () => ({ width: '10%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {(row.provenienza !== 'F' && row.provenienza !== 'D') ? (
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'trash']}
                size="lg"
                title="Elimina"
                id={`Visualizza${row.fk_sequ_competenza}`}
                style={{ color: themeColors.danger }}
                onClick={() => onDeleteCompetenza(row)}
              />
            ) : (
              <Form.Label>
                Ereditato da:
                {' '}
                <strong>{row.provenienza === 'F' ? 'Famiglia Professionale' : 'Dimensione Professionale'}</strong>
              </Form.Label>
            )}
          </div>
        ),
      },
    ]
  );
};

export default fieldsCompetenze;
