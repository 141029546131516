import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { optionsTipoBatch } from './constants/optionsBatch';

function FormUtentiMassivo({
  stateBatch,
  onChange,
  errors,
}) {
  return (
    <Form.Group as={Col} lg controlId="form_aggiungi_utente_massivo">
      <Form.Label style={{ fontWeight: 'bold' }}>
        Inserisci File
      </Form.Label>
      <Form.Control
        name="file"
        type="file"
        size="sm"
        required
        onChange={onChange}
        accept=".xlsx"
        isInvalid={errors?.file}
      />
      <Form.Control.Feedback type="invalid">
        *Campo File obbligatorio
      </Form.Control.Feedback>
      <Form.Label style={{ fontWeight: 'bold' }}>
        Tipo Caricamento
      </Form.Label>
      <Select
        id="codi_tipo_batch"
        name="codi_tipo_batch"
        options={optionsTipoBatch}
        placeholder="Scegli Tipo caricamento"
        getOptionLabel={(option) => option.tipo_batch}
        getOptionValue={(option) => option.codi_tipo_batch}
        value={optionsTipoBatch?.filter(
          (el) => el.codi_tipo_batch === stateBatch.codi_tipo_batch,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
        isInvalid={errors?.codi_tipo_batch}
        required
      />
      <Form.Control.Feedback type="invalid">
        *Campo Tipo caricamento obbligatorio
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default FormUtentiMassivo;
