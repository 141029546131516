import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import { Context } from '../../../utils/Context';
import Table from '../../../components/Shared/Table';
import fieldsFamiglieProfessionali from './fieldsTable';
import { getItem, setItem } from '../../../utils/storage';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import checkStorage from '../../../utils/checkSessionStorage';
import FiltriFamiglieProfessionali from './filtriFamiglieProfessionali';
import EmptyState from '../../../components/EmptyState';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';

function FamiglieProfessionali() {
  const [context, setContext] = useContext(Context);
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [famigliaProfessionaleSelected, setFamigliaProfessionaleSelected] = useState();
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idAmministrazione } = JSON.parse(getItem('identita'));

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionali(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filterSession, offset, limit);
      setFamiglieProfessionaliList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      fk_sequ_comparto: sequ_k_comparto,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getFamiglieProfessionali(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getFamiglieProfessionali(
      offset,
      10,
      storage.page,
      { ...storage.filtri, fk_sequ_comparto: sequ_k_comparto },
    );
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    setFiltri({ ...filtri, fk_sequ_comparto: sequ_k_comparto });
    getFamiglieProfessionali(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
  }, [sequ_k_comparto]);

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e) {
    const { value, name, checked } = e.target;
    if (name === 'banca_dati_unica') {
      filtri[name] = checked;
    } else {
      setFiltri({
        ...filtri,
        [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getFamiglieProfessionali(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
  }

  // function collegata al servizio di eliminazione di una famiglia professionale
  async function eliminaFamigliaProfessionale(idFamigliaProfessionale) {
    SistemaProfessionaleService.eliminaFamigliaProfessionale(idFamigliaProfessionale)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Funzione attivata al click dell'icona di eliminazione
  const onDelete = (famigliaProfessionale) => {
    setModalShowDelete(true);
    setFamigliaProfessionaleSelected(famigliaProfessionale);
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (idFamigliaProfessionale) => {
    history.push(
      `/area-riservata/famiglie-professionali/${idFamigliaProfessionale}`,
      { isModify: false },
    );
  };

  // Funzione attivata al click dell'icona di modifica
  const onModify = (idFamigliaProfessionale) => {
    history.push(
      `/area-riservata/famiglie-professionali/${idFamigliaProfessionale}`,
      { isModify: true },
    );
  };

  // Funzione attivata al click dell'icona di aggiungi
  const onCreate = () => {
    history.push('/area-riservata/famiglie-professionali/nuovo');
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Famiglie Professionali"
          showRefresh
          functionRefresh={refresh}
          showAdd={controlla('X43') && sequ_k_comparto}
          functionAdd={() => onCreate()}
        />
        <FiltriFamiglieProfessionali
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getFamiglieProfessionali={getFamiglieProfessionali}
        />
        {famiglieProfessionaliList.length > 0 ? (
          <Table
            data={famiglieProfessionaliList}
            fields={fieldsFamiglieProfessionali(onDelete, onView, onModify, idAmministrazione)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="famiglie_professionali_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà la famiglia professionale ${famigliaProfessionaleSelected?.codice}`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaFamigliaProfessionale(famigliaProfessionaleSelected.id)}
      />
    </>
  );
}

export default FamiglieProfessionali;
