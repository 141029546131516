import {
  React, useContext, useEffect, useState,
} from 'react';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import Table from '../../../components/Shared/Table';
import fields from './fieldsTable';
import history from '../../../utils/history';
import Filtri from './filtri';
import richiestIdentitaService from '../../../services/richiestaIdentitaService';
import checkStorage from '../../../utils/checkSessionStorage';
import { setItem } from '../../../utils/storage';
import { controlla } from '../../../utils/verificaPermessi';
import { Context } from '../../../utils/Context';

function GestioneRichiesteIdentitaPerUtente() {
  const initialFilters = {
    codi_k_tipo_stato: '',
    data_richiesta_from: '',
    data_richiesta_to: '',
  };
  const [context, setContext] = useContext(Context);
  const [filtri, setFiltri] = useState(initialFilters);
  const [richiesteIdentita, setRichiesteIdentita] = useState([]);
  const [stati, setStati] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  async function getRichiesteIdentita(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await richiestIdentitaService
        .getRichiesteIdentitaPerUtente(filterSession, offset, limit);
      setRichiesteIdentita(response.data);
      setPagination({
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function getStatiRichieste() {
    try {
      setStati(await richiestIdentitaService.getStati());
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getRichiesteIdentita(currentIndex, sizePerPage, page);
  };

  const actions = {

  };

  function refresh() {
    getRichiesteIdentita(0, 10, 1);
  }

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getRichiesteIdentita(offset, 10, storage.page, storage.filtri);
    getStatiRichieste();
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  return (
    <>
      <Title
        title="Richiesta accreditamento"
        subtitle="È possibile gestire le richieste fatte all'amministratore per la gestione delle PA"
        showRefresh
        functionRefresh={refresh}
        showAdd={controlla('X19') === true}
        functionAdd={() => history.push('/area-riservata/richiesta-identita/aggiungi')}
      />

      <Filtri
        filtri={filtri}
        setFiltri={setFiltri}
        onChangeFiltri={onChangeFiltri}
        getRichiesteIdentita={getRichiesteIdentita}
        stati={stati}
      />
      <Table
        title="Gestione richieste identità"
        data={richiesteIdentita}
        fields={fields(actions)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="sequ_k_richiesta"
      />
    </>
  );
}

export default GestioneRichiesteIdentitaPerUtente;
