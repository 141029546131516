import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
} from 'react-bootstrap';
import { dataAttuale } from '../../../utils/dataAttuale';
import { setItem } from '../../../utils/storage';

function Filtri({
  filtri,
  setFiltri,
  onChangeFiltri,
  getRichiesteIdentita,
  stati,
}) {
  const initialState = {
    ...filtri,
    codi_stato_richiesta: '',
    dataRichiestaFrom: '',
    dataRichiestaTo: '',
  };

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="codi_stato_richiesta">
                <Form.Label>Stato richiesta</Form.Label>
                <Form.Select
                  onChange={onChangeFiltri}
                  value={filtri.codi_k_stato}
                >
                  <option> </option>
                  {stati.map((stato) => (
                    <option
                      key={stato.codi_k_stato}
                      value={stato.codi_k_stato}
                    >
                      {stato.desc_stato}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_richiesta_from">Data invio richiesta dal</Form.Label>
                <input
                  type="date"
                  id="data_richiesta_from"
                  name="dataRichiestaFrom"
                  min="2021-01-01"
                  max={dataAttuale()}
                  className="form-control"
                  onChange={onChangeFiltri}
                  value={filtri.data_richiesta_from}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label htmlFor="data_richiesta_to">Data invio richiesta al</Form.Label>
                <input
                  type="date"
                  id="data_richiesta_to"
                  name="dataRichiestaTo"
                  min={filtri.data_richiesta_from || '2021-01-01'}
                  max={dataAttuale()}
                  className="form-control"
                  onChange={onChangeFiltri}
                  value={filtri.data_richiesta_to}
                />
              </Form.Group>
            </Row>
            <Button
              variant="outline-dark"
              onClick={() => {
                setFiltri(initialState);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eraser']} style={{ marginRight: '2px' }} />
              PULISCI CAMPI
            </Button>
            {' '}
            <Button variant="primary" onClick={() => { setItem('filtri', JSON.stringify(filtri)); getRichiesteIdentita(0, 5, 1); }}>
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Filtri;
