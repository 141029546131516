import {
  React, useContext, useEffect, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import Title from '../../../../components/GestisciUtenti/TitoloPagina/index';
import Table from '../../../../components/Shared/Table';
import fields from './fieldsTable';
import history from '../../../../utils/history';
import Filtri from './filtri';
import richiestIdentitaService from '../../../../services/richiestaIdentitaService';
import checkStorage from '../../../../utils/checkSessionStorage';
import { setItem } from '../../../../utils/storage';
import { controlla } from '../../../../utils/verificaPermessi';
import { Context } from '../../../../utils/Context';

function GestioneRichiesteIdentita() {
  const initialFilters = {
    codi_k_tipo_stato: '',
    data_richiesta_from: '',
    data_richiesta_to: '',
  };
  const [context, setContext] = useContext(Context);
  const [filtri, setFiltri] = useState(initialFilters);
  const [richiesteIdentita, setRichiesteIdentita] = useState([]);
  const [stati, setStati] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  async function getRichiesteIdentita(offset, limit, page, filters) {
    const filterSession = filters || filtri;
    const response = await richiestIdentitaService
      .getRichiesteIdentita(filterSession, offset, limit);
    setRichiesteIdentita(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  async function getStatiRichieste() {
    try {
      setStati(await richiestIdentitaService.getStati());
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getRichiesteIdentita(currentIndex, sizePerPage, page);
  };

  const actions = {

  };

  function refresh() {
    getRichiesteIdentita(0, 5, 1);
  }

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getRichiesteIdentita(offset, 5, storage.page, storage.filtri);
    getStatiRichieste();
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  return (
    <>
      <Title
        title="Gestione richieste accreditamento"
        subtitle="È possibile gestire le richieste di accreditamento inviate dalle amministrazioni"
        showRefresh
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
        functionRefresh={refresh}
      />

      <Filtri
        filtri={filtri}
        onChangeFiltri={onChangeFiltri}
        getRichiesteIdentita={getRichiesteIdentita}
        stati={stati}
      />

      <Table
        title="Elenco richieste di accreditamento"
        data={richiesteIdentita}
        fields={fields(actions)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="sequ_k_richiesta"
      />
    </>
  );
}

export default GestioneRichiesteIdentita;
