import React from 'react';
import {
  Card, Button, Form, Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { getItem } from '../../../utils/storage';
import './index.css';

function IdentitaCard({
  id,
  identita,
  isDefault,
  handleOnChange,
  getPermessiByIdentita,
  amministrazione,
  idAmministrazione,
  idComparto,
  comparto,
}) {
  return (
    <Col className="mb-3">
      <Card className="text-center" style={{ height: '100%' }}>
        <Card.Body>
          {(JSON.parse(getItem('user'))).trusted
            ? (
              <div className="d-flex flex-column align-items-center">
                <Form.Check
                  type="checkbox"
                  id={id}
                >
                  <Form.Check.Input
                    type="checkbox"
                    checked={isDefault}
                    onChange={handleOnChange}
                    className="form-check-input-dark mb-3"
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-${id}`}>Imposta identità di default</Tooltip>}
                  >
                    <Form.Check.Label className="w-100 fw-bold" htmlFor={id}>
                      {amministrazione}
                      <br />
                      {identita}
                    </Form.Check.Label>
                  </OverlayTrigger>
                </Form.Check>
              </div>
            )
            : <p className="fw-bold">{identita}</p>}

          <br />
          <Button onClick={() => { getPermessiByIdentita(id, identita, idAmministrazione, amministrazione, idComparto, comparto); }} aria-label={`Entra con ${identita}`}>Entra</Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default IdentitaCard;
