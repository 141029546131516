import { useContext, useEffect, useState } from 'react';
import {
  Row, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '../../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import FormRichiestaIdentita from '../../../../../components/RichiesteIdentita/Form';
import richiestIdentitaService from '../../../../../services/richiestaIdentitaService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';
import ModaleRifiutoRichiesta from '../../../../../components/RichiesteIdentita/ModaleRifiutoRichiesta';
import ModaleAccettazioneRichiesta from '../../../../../components/RichiesteIdentita/ModaleAccettazioneRichiesta';

function DettaglioRichiestaIdentitaAmministratore() {
  const { idRichiesta } = useParams();
  const [context, setContext] = useContext(Context);
  const [richiestaIdentita, setRichiestaIdentita] = useState({});
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [openCloseModaleRifiuto, setOpenCloseModaleRifiuto] = useState(false);
  const [openCloseModaleEvasione, setOpenCloseModaleEvasione] = useState(false);
  const [stati, setStati] = useState([]);
  const [options, setOptions] = useState([]);
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleStateModaleRifiuto() {
    setOpenCloseModaleRifiuto(!openCloseModaleRifiuto);
  }

  function handleStateModaleEvasione() {
    setOpenCloseModaleEvasione(!openCloseModaleEvasione);
  }

  async function presaInCarico() {
    richiestIdentitaService.presaIncaricoRichiesta(idRichiesta)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-richieste-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function evadi() {
    richiestIdentitaService
      .evadiRichiesta(idRichiesta, parseInt(richiestaIdentita.fk_sequ_comparto, 10))
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-richieste-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function rifiuta() {
    richiestIdentitaService.negaRichiesta(idRichiesta, richiestaIdentita.desc_msg_risposta || '')
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/amministrazione/gestione-richieste-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  function onChange(e) {
    const { value } = e.target;
    const { id } = e.target;
    setRichiestaIdentita({
      ...richiestaIdentita,
      [id]: value,
    });
  }

  async function getRichiestaIdentita() {
    const res = await richiestIdentitaService.getRichiestaIdentitaByIdAdmin(idRichiesta);
    setRichiestaIdentita(res);
    if (res.codi_ipa) {
      const dettaglioIpa = await richiestIdentitaService
        .getIpaByCodiAdmin(res.codi_ipa);
      setOptions([{ cod_amm: res.codi_ipa, des_amm: dettaglioIpa.des_amm }]);
    }
  }

  function campiCompilati() {
    if (richiestaIdentita.desc_email && richiestaIdentita.codi_ipa
      && richiestaIdentita.desc_nome_responsabile && richiestaIdentita.desc_cognome_responsabile
      && richiestaIdentita.desc_cf_responsabile) {
      return true;
    }
    return false;
  }

  async function getStatiRichieste() {
    try {
      setStati(await richiestIdentitaService.getStati());
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getRichiestaIdentita();
    getStatiRichieste();
  }, []);

  return (
    <>
      <Row id="form-richiesta-identita">
        <Row>
          <Title
            title="Dettaglio richiesta di accreditamento"
            subtitle="È possibile visualizzare i dettagli della richiesta di accreditamento"
            showIndietro
            functionIndietro={() => history.push('/area-riservata/amministrazione/gestione-richieste-identita')}
          />
        </Row>
        <FormRichiestaIdentita
          richiestaIdentita={richiestaIdentita}
          disabled
          context={context}
          setContext={setContext}
          campiCompilati={campiCompilati}
          stati={stati}
          isAdmin
          options={options}
          setOptions={setOptions}
        />
        <div className="d-flex justify-content-end">
          {richiestaIdentita.codi_stato_richiesta === 2
            ? (
              <Button variant="primary" onClick={handleStateModaleConferma}>
                <FontAwesomeIcon aria-hidden="true" icon="arrow-up" />
                {' '}
                Prendi in carico
              </Button>
            ) : null }
        </div>
        <div className="d-flex justify-content-between">
          {richiestaIdentita.codi_stato_richiesta === 3
            ? (
              <Button variant="danger" onClick={handleStateModaleRifiuto}>
                <FontAwesomeIcon aria-hidden="true" icon="x" />
                {' '}
                Rifiuta
              </Button>
            ) : null }
          {richiestaIdentita.codi_stato_richiesta === 3
            ? (
              <Button variant="primary" onClick={handleStateModaleEvasione}>
                <FontAwesomeIcon aria-hidden="true" icon="check" />
                {' '}
                Evadi
              </Button>
            ) : null }
        </div>
      </Row>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={'L\'operazione effettuerà la presa in carico'}
        show={openCloseModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={presaInCarico}
      />
      <ModaleAccettazioneRichiesta
        richiestaIdentita={richiestaIdentita}
        onChange={onChange}
        evasione={evadi}
        handleStateModale={handleStateModaleEvasione}
        show={openCloseModaleEvasione}
      />
      <ModaleRifiutoRichiesta
        richiestaIdentita={richiestaIdentita}
        onChange={onChange}
        evasione={rifiuta}
        handleStateModale={handleStateModaleRifiuto}
        show={openCloseModaleRifiuto}
      />
    </>
  );
}

export default DettaglioRichiestaIdentitaAmministratore;
