import api from '../utils/api';

const sistemaProfessionaleUrl = process.env.REACT_APP_SISTEMA_PROFESSIONALE_URL;

// function sezione: AREA CONTRATTUALE
async function getAreaContrattuale(filters, offset, limit) {
  let params = '';
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/area-contrattuale/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaAreaContrattuale(areaContrattuale) {
  return api.post(`${sistemaProfessionaleUrl}/area-contrattuale`, areaContrattuale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getAreaContrattualeById(id) {
  return api.get(`${sistemaProfessionaleUrl}/area-contrattuale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaAreaContrattuale(id, areaContrattuale) {
  return api.patch(`${sistemaProfessionaleUrl}/area-contrattuale/${id}`, areaContrattuale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaAreaContrattuale(id) {
  return api.delete(`${sistemaProfessionaleUrl}/area-contrattuale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: FAMIGLIE PROFESSIONALI
async function getFamiglieProfessionali(filters, offset, limit) {
  let params = '';
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (filters.fk_codi_amministrazione) {
    params += `fk_codi_amministrazione=${filters.fk_codi_amministrazione}&`;
  }
  if (filters.banca_dati_unica || filters.banca_dati_unica === false) {
    params += `banca_dati_unica=${filters.banca_dati_unica}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/famiglia-professionale/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaFamigliaProfessionale(famigliaProfessionale) {
  return api.post(`${sistemaProfessionaleUrl}/famiglia-professionale`, famigliaProfessionale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getFamigliaProfessionaleById(id) {
  return api.get(`${sistemaProfessionaleUrl}/famiglia-professionale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getFamiglieProfessionaliByIdProfiloProfessionale(id, filters, offset, limit) {
  let params = '';
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  if (filters.banca_dati_unica || filters.banca_dati_unica === false) {
    params += `banca_dati_unica=${filters.banca_dati_unica}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/famiglia-professionale/profilo-professionale/${id}/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaFamigliaProfessionale(id, famigliaProfessionale) {
  return api.patch(`${sistemaProfessionaleUrl}/famiglia-professionale/${id}`, famigliaProfessionale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaFamigliaProfessionale(id) {
  return api.delete(`${sistemaProfessionaleUrl}/famiglia-professionale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: DIMENSIONI PROFESSIONALI
async function getDimensioniProfessionali(filters, offset, limit) {
  let params = '';
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (filters.fk_codi_amministrazione) {
    params += `fk_codi_amministrazione=${filters.fk_codi_amministrazione}&`;
  }
  if (filters.banca_dati_unica || filters.banca_dati_unica === false) {
    params += `banca_dati_unica=${filters.banca_dati_unica}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/dimensione-professionale/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaDimensioneProfessionale(dimensioneProfessionale) {
  return api.post(`${sistemaProfessionaleUrl}/dimensione-professionale`, dimensioneProfessionale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getDimensioneProfessionaleById(id) {
  return api.get(`${sistemaProfessionaleUrl}/dimensione-professionale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaDimensioneProfessionale(id, dimensioneProfessionale) {
  return api.patch(`${sistemaProfessionaleUrl}/dimensione-professionale/${id}`, dimensioneProfessionale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaDimensioneProfessionale(id) {
  return api.delete(`${sistemaProfessionaleUrl}/dimensione-professionale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: AREE DELLE COMPETENZE
async function getAreeCompetenze(filters, offset, limit) {
  let params = '';
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (filters.fk_codi_amministrazione) {
    params += `fk_codi_amministrazione=${filters.fk_codi_amministrazione}&`;
  }
  if (filters.codi_tipo_competenza) {
    params += `codi_tipo_competenza=${filters.codi_tipo_competenza}&`;
  }
  if (filters.banca_dati_unica || filters.banca_dati_unica === false) {
    params += `banca_dati_unica=${filters.banca_dati_unica}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/area-competenze/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaAreaCompetenze(areaCompetenze) {
  return api.post(`${sistemaProfessionaleUrl}/area-competenze`, areaCompetenze)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getAreaCompetenzeById(id) {
  return api.get(`${sistemaProfessionaleUrl}/area-competenze/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaAreaCompetenze(id, areaCompetenze) {
  return api.patch(`${sistemaProfessionaleUrl}/area-competenze/${id}`, areaCompetenze)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaAreaCompetenze(id) {
  return api.delete(`${sistemaProfessionaleUrl}/area-competenze/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: AMBITI DI RUOLO
async function getAmbitiRuolo(filters, offset, limit) {
  let params = '';
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (filters.fk_codi_amministrazione) {
    params += `fk_codi_amministrazione=${filters.fk_codi_amministrazione}&`;
  }
  if (filters.fk_sequ_famiglia_professionale) {
    params += `fk_sequ_famiglia_professionale=${filters.fk_sequ_famiglia_professionale}&`;
  }
  if (filters.banca_dati_unica || filters.banca_dati_unica === false) {
    params += `banca_dati_unica=${filters.banca_dati_unica}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/ambito-ruolo/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoAmbitoRuolo(ambitoRuolo) {
  return api.post(`${sistemaProfessionaleUrl}/ambito-ruolo`, ambitoRuolo)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getAmbitoRuoloById(id) {
  return api.get(`${sistemaProfessionaleUrl}/ambito-ruolo/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaAmbitoRuolo(id) {
  return api.delete(`${sistemaProfessionaleUrl}/ambito-ruolo/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaAmbitoRuolo(id, ambitoRuolo) {
  return api.patch(`${sistemaProfessionaleUrl}/ambito-ruolo/${id}`, ambitoRuolo)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: COMPETENZE
async function getCompetenze(filters, offset, limit) {
  let params = '';
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.codi_tipo_competenza) {
    params += `codi_tipo_competenza=${filters.codi_tipo_competenza}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/competenza/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovaCompetenza(competenza) {
  return api.post(`${sistemaProfessionaleUrl}/competenza`, competenza)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getCompetenzaById(id) {
  return api.get(`${sistemaProfessionaleUrl}/competenza/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaCompetenza(id) {
  return api.delete(`${sistemaProfessionaleUrl}/competenza/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaCompetenza(id, competenza) {
  return api.patch(`${sistemaProfessionaleUrl}/competenza/${id}`, competenza)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: GRADING
async function getGrading(filters, offset, limit) {
  let params = '';
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.codi_tipo_competenza) {
    params += `codi_tipo_competenza=${filters.codi_tipo_competenza}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/grading/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoGrading(grading) {
  return api.post(`${sistemaProfessionaleUrl}/grading`, grading)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getGradingById(id) {
  return api.get(`${sistemaProfessionaleUrl}/grading/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaGrading(id) {
  return api.delete(`${sistemaProfessionaleUrl}/grading/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaGrading(id, grading) {
  return api.patch(`${sistemaProfessionaleUrl}/grading/${id}`, grading)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: PROFILO RUOLO
async function getProfiliRuolo(filters, offset, limit) {
  let params = '';
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.desc_attivita) {
    params += `desc_attivita=${filters.desc_attivita}&`;
  }
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (filters.fk_codi_amministrazione) {
    params += `fk_codi_amministrazione=${filters.fk_codi_amministrazione}&`;
  }
  if (filters.fk_sequ_profilo_professionale) {
    params += `fk_sequ_profilo_professionale=${filters.fk_sequ_profilo_professionale}&`;
  }
  if (filters.fk_sequ_ambito_ruolo) {
    params += `fk_sequ_ambito_ruolo=${filters.fk_sequ_ambito_ruolo}&`;
  }
  if (filters.banca_dati_unica || filters.banca_dati_unica === false) {
    params += `banca_dati_unica=${filters.banca_dati_unica}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/profilo-ruolo/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoProfiloRuolo(profiloRuolo) {
  return api.post(`${sistemaProfessionaleUrl}/profilo-ruolo`, profiloRuolo)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getProfiloRuoloById(id) {
  return api.get(`${sistemaProfessionaleUrl}/profilo-ruolo/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaProfiloRuolo(id) {
  return api.delete(`${sistemaProfessionaleUrl}/profilo-ruolo/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaProfiloRuolo(id, ambitoRuolo) {
  return api.patch(`${sistemaProfessionaleUrl}/profilo-ruolo/${id}`, ambitoRuolo)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: PROFILI PROFESSIONALI
async function getProfiliProfessionali(filters, offset, limit) {
  let params = '';
  if (filters.desc_titolo) {
    params += `desc_titolo=${encodeURIComponent(filters.desc_titolo)}&`;
  }
  if (filters.desc_codice) {
    params += `desc_codice=${encodeURIComponent(filters.desc_codice)}&`;
  }
  if (filters.fk_sequ_comparto) {
    params += `fk_sequ_comparto=${filters.fk_sequ_comparto}&`;
  }
  if (filters.banca_dati_unica || filters.banca_dati_unica === false) {
    params += `banca_dati_unica=${filters.banca_dati_unica}&`;
  }
  if (filters.sortField) {
    params += `sortField=${filters.sortField}&`;
  }
  if (filters.sortOrder) {
    params += `sortOrder=${filters.sortOrder}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${sistemaProfessionaleUrl}/profilo-professionale/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoProfiloProfessionale(profiloProfessionale) {
  return api.post(`${sistemaProfessionaleUrl}/profilo-professionale`, profiloProfessionale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getProfiloProfessionaleById(id) {
  return api.get(`${sistemaProfessionaleUrl}/profilo-professionale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaProfiloProfessionale(id) {
  return api.delete(`${sistemaProfessionaleUrl}/profilo-professionale/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaProfiloProfessionale(id, profiloProfessionale) {
  return api.patch(`${sistemaProfessionaleUrl}/profilo-professionale/${id}`, profiloProfessionale)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getReportProfiliProfessionali(comparto) {
  return api.get(`${sistemaProfessionaleUrl}/report/profili-professionali?fk_sequ_comparto=${comparto}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getReportFamiglieProfessionali() {
  return api.get(`${sistemaProfessionaleUrl}/report/famiglie-professionali`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getReportProfiliRuolo(comparto) {
  return api.get(`${sistemaProfessionaleUrl}/report/profili-ruolo?fk_sequ_comparto=${comparto}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getReportCompetenze() {
  return api.get(`${sistemaProfessionaleUrl}/report/competenze`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getReportCompetenzePerTipologia() {
  return api.get(`${sistemaProfessionaleUrl}/report/competenze-per-tipologia`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getReportProfiliRuoloPerAmbito(comparto) {
  return api.get(`${sistemaProfessionaleUrl}/report/profili-ruolo-per-ambito?fk_sequ_comparto=${comparto}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const SistemaProfessionaleService = {
  getAreaContrattuale,
  nuovaAreaContrattuale,
  getAreaContrattualeById,
  modificaAreaContrattuale,
  eliminaAreaContrattuale,
  getFamiglieProfessionali,
  nuovaFamigliaProfessionale,
  getFamigliaProfessionaleById,
  getFamiglieProfessionaliByIdProfiloProfessionale,
  modificaFamigliaProfessionale,
  eliminaFamigliaProfessionale,
  getDimensioniProfessionali,
  nuovaDimensioneProfessionale,
  getDimensioneProfessionaleById,
  modificaDimensioneProfessionale,
  eliminaDimensioneProfessionale,
  getAreeCompetenze,
  nuovaAreaCompetenze,
  getAreaCompetenzeById,
  modificaAreaCompetenze,
  eliminaAreaCompetenze,
  getAmbitiRuolo,
  nuovoAmbitoRuolo,
  getAmbitoRuoloById,
  modificaAmbitoRuolo,
  eliminaAmbitoRuolo,
  getCompetenze,
  nuovaCompetenza,
  getCompetenzaById,
  eliminaCompetenza,
  modificaCompetenza,
  getGrading,
  nuovoGrading,
  getGradingById,
  eliminaGrading,
  modificaGrading,
  getProfiliRuolo,
  nuovoProfiloRuolo,
  getProfiloRuoloById,
  eliminaProfiloRuolo,
  modificaProfiloRuolo,
  getProfiliProfessionali,
  nuovoProfiloProfessionale,
  getProfiloProfessionaleById,
  eliminaProfiloProfessionale,
  modificaProfiloProfessionale,
  getReportProfiliProfessionali,
  getReportFamiglieProfessionali,
  getReportProfiliRuolo,
  getReportCompetenze,
  getReportCompetenzePerTipologia,
  getReportProfiliRuoloPerAmbito,
};

export default SistemaProfessionaleService;
