import React, { useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Context } from '../../../utils/Context';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import checkStorage from '../../../utils/checkSessionStorage';
import { setItem } from '../../../utils/storage';
import Table from '../../Shared/Table';
import fieldsSelezioneCompetenze from './fieldsTable';
import FiltriCompetenze from '../../../pages/area-riservata/competenze/filtriCompetenze';
import EmptyState from '../../EmptyState';

function FormSelezioneCompetenze({
  tipoCompetenza,
  competenzeListSelected,
  setCompetenzeListSelected,
}) {
  const [competenzeList, setCompetenzeList] = useState([]);
  const [context, setContext] = useContext(Context);
  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  // Function collegata al servizio per ottenere la lista delle competenze
  async function getCompetenze(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getCompetenze(filterSession, offset, limit);
      setCompetenzeList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      codi_tipo_competenza: tipoCompetenza,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getCompetenze(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getCompetenze(
      offset,
      5,
      storage.page,
      { ...storage.filtri, codi_tipo_competenza: tipoCompetenza },
    );
  }, []);

  // useEffect per caricare i dati presenti nella tabella + la lista delle famiglie professionali
  useEffect(() => {
    setFiltri({ codi_tipo_competenza: tipoCompetenza });
    getCompetenze(0, 5, 1, { codi_tipo_competenza: tipoCompetenza });
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // Function per la selezione delle competenze da aggiungere
  const onCheck = (event, competenza) => {
    if (event.target.checked) {
      const newCompetenza = {
        ...competenza,
        fk_sequ_competenza: competenza.sequ_k_competenza,
        grading: 0,
      };
      delete newCompetenza.sequ_k_competenza;
      setCompetenzeListSelected((list) => [...list, newCompetenza]);
    } else {
      setCompetenzeListSelected((list) => list.filter(
        (el) => el.fk_sequ_competenza !== competenza.sequ_k_competenza,
      ));
    }
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e) {
    const { value, name } = e.target;
    setFiltri({
      ...filtri, [name]: value,
    });
  }

  return (
    <Form.Group as={Col} lg controlId="selezione-competenze">
      <FiltriCompetenze
        filtri={filtri}
        setFiltri={setFiltri}
        onChangeFiltri={onChangeFiltri}
        getCompetenze={getCompetenze}
      />
      {competenzeList.length > 0 ? (
        <Table
          data={competenzeList}
          fields={fieldsSelezioneCompetenze(onCheck, competenzeListSelected)}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="selezione_competenze_list"
        />
      ) : (
        <EmptyState marginTop="3rem" />
      )}

    </Form.Group>
  );
}

export default FormSelezioneCompetenze;
