import {
  React, useContext, useEffect, useState,
} from 'react';
import { color } from 'echarts';
import Table from '../../../../components/Shared/Table';
import utenteService from '../../../../services/utentiService';
import checkStorage from '../../../../utils/checkSessionStorage';
import { setItem } from '../../../../utils/storage';
import EmptyState from '../../../../components/EmptyState';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina/index';
import { Context } from '../../../../utils/Context';
import fieldsUtentiMassivo from './fieldsTable';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormUtentiMassivo from './FormUtentiMassivo';
import FiltriUtentiMassiva from './filtriUtentiMassiva';
import ExportDropdownButton from '../../../../components/ExportButton/ExportDropdownButton';
import fileTemplateRuoli from '../../../../images/template_ruoli.xlsx';
import fileTemplateUtenti from '../../../../images/template_utenti.xlsx';

function GestioneUtentiMassiva() {
  const [context, setContext] = useContext(Context);
  const [listaBatch, setListaBatch] = useState([]);
  const [stateBatch, setStateBatch] = useState({
    file: '',
    codi_tipo_batch: '',
  });
  const [modalShow, setModalShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({});

  // Validazione per la creazione di un nuovo utente
  const validateCreaBatch = (state, error) => {
    const temp = { ...error };
    temp.file = state.file === ''; // CONTROLLARE
    temp.codi_tipo_batch = state.codi_tipo_batch === '';
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // Function collegata al servizio per ottenere la lista dei batch caricati
  async function getBatch(offset, limit, page, filters) {
    const filtriSession = filters || filtri;
    const response = await utenteService.getBatch(filtriSession, offset, limit);
    setListaBatch(response);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getBatch(0, 10, 1);
  }

  // Function collegata al servizio per caricare e creare un nuovo batch
  async function creaBatch() {
    utenteService.creaBatch(stateBatch)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShow(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Function  per il cambiamento della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      sortField: type === 'sort' ? sortField : 'sequ_k_elaborazione_batch',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getBatch(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // Function per il cambiamento dei filtri
  function onChangeFiltri(e, config) {
    setFiltri({
      ...filtri,
      [config.name]: e?.[config.name],
    });
  }

  // Function per modificare lo stato del batch che l'utente sta modificando.
  const onChange = (e, config) => {
    if (config) {
      const newState = { ...stateBatch, [config.name]: e?.codi_tipo_batch };
      setStateBatch(newState);
      validateCreaBatch(newState, errors);
    } else {
      const { files } = e.target;
      setStateBatch({
        ...stateBatch,
        file: files[0],
      });
      validateCreaBatch({ ...stateBatch, file: files[0] }, errors);
    }
  };

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getBatch(offset, 10, storage.page, storage.filtri);
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  return (
    <>
      <TitlePage
        title="Gestione Utenti Massiva"
        subtitle="È possibile caricare i file per la creazione degli utenti e per l'associazione dei profili di ruolo. La tabella ti fornirà indicazioni
        circa lo stato di avanzamento dei caricamente. Ricorda: l'esecuzione del processo è prevista all'inizio di ogni ora."
        showRefresh
        showAdd
        functionRefresh={() => {
          getBatch(0, 10, 1);
        }}
        functionAdd={() => setModalShow(true)}
        showButtonExport
        buttonExport={(
          <ExportDropdownButton
            dropdownTitle="SCARICA TEMPLATE"
            items={[
              {
                title: 'template utenti',
                fileName: 'template_utenti.xlsx',
                href: fileTemplateUtenti,
              },
              {
                title: 'template ruoli',
                fileName: 'template_ruoli.xlsx',
                href: fileTemplateRuoli,
              },
            ]}
          />
        )}
      />
      <FiltriUtentiMassiva
        filtri={filtri}
        setFiltri={setFiltri}
        getBatch={getBatch}
        onChangeFiltri={onChangeFiltri}
      />
      {listaBatch.length > 0 ? (
        <Table
          title="Elenco File"
          data={listaBatch}
          fields={fieldsUtentiMassivo}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="sequ_k_elaborazione_batch"
        />
      ) : (
        <EmptyState marginTop="3rem" />
      )}
      <ModaleCentroVerticale
        modalTitle="Aggiungi Utenti Massivo"
        modalBody={(
          <FormUtentiMassivo
            stateBatch={stateBatch}
            onChange={onChange}
            errors={errors}
          />
        )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => creaBatch()}
        labelConfirm="Carica"
        onClose={() => { setModalShow(false); }}
      />
    </>
  );
}

export default GestioneUtentiMassiva;
