import React, { useEffect, useState } from 'react';
import { getItem } from '../../utils/storage';
import ReportSistemaProfessionale from '../../components/Report/sistemaProfessionale';
import { controlla } from '../../utils/verificaPermessi';
import ReportAssessment from '../../components/Report/assessment';

function Home() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <div className="m-2" style={{ textAlign: 'center' }}>
      <h1 className="mb-2">{(JSON.parse(getItem('identita'))).amministrazione}</h1>
      <p className="h4">Benvenuto nella tua area riservata.</p>
      <p id="desc">Scegli una delle funzioni dal menu di lato.</p>
      {show && controlla('X21') && <ReportSistemaProfessionale />}
      {show && controlla('X22') && <ReportAssessment />}
    </div>
  );
}

export default Home;
