import {
  React, useContext, useEffect, useState,
} from 'react';
import FormGestione from '../../../../components/GestisciUtenti/Form/index';
import Table from '../../../../components/Shared/Table';
import { fieldsProfonditaStorica, fieldsNoProfonditaStorica } from './fieldsTable';
import history from '../../../../utils/history';
import utenteService from '../../../../services/utentiService';
import checkStorage from '../../../../utils/checkSessionStorage';
import { setItem } from '../../../../utils/storage';
import EmptyState from '../../../../components/EmptyState';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina/index';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormGestioneUtenti from './FormGestioneUtenti';
import { Context } from '../../../../utils/Context';

function GestisciUtenti() {
  const [context, setContext] = useContext(Context);
  const [utenti, setUtenti] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const [isNew, setIsNew] = useState(false);
  const [modalCheck, setModalCheck] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [stateUtente, setStateUtente] = useState({
    desc_nome: '',
    desc_cognome: '',
    codi_id_fiscale: '',
    desc_email: '',
    desc_area_contrattuale: '',
    desc_profilo_accesso: '',
    desc_struttura_organizzativa_i_liv: '',
    desc_struttura_organizzativa_ii_liv: '',
  });

  const [filtri, setFiltri] = useState({
    identificativo: '',
    desc_nome: '',
    desc_cognome: '',
    trusted: null,
    fk_codi_tipo_connettore: null,
    data_registrazione_from: null,
    data_registrazione_to: null,
    profondita_storica: false,
  });

  // Validazione per la creazione di un nuovo utente
  const validateCreaUtente = (state, error) => {
    const temp = { ...error };
    temp.desc_nome = state.desc_nome === '';
    temp.desc_cognome = state.desc_cognome === '';
    temp.codi_id_fiscale = state.codi_id_fiscale === '';
    temp.desc_email = state.desc_email === '';
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // Function collegata al servizio per ottenere la lista degli utenti
  async function getUtenti(offset, limit, page, filters) {
    const filtriSession = filters || filtri;
    const response = await utenteService.getUtenti(filtriSession, offset, limit);
    setUtenti(response.data);
    setPagination({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  // Function collegata al servizio per ottenere l'utente in base al CF
  async function getUtenteByIdentificativo(identificativo) {
    const response = await utenteService.getUtenteByIdentificativo(identificativo);
    if (response[0]?.id) {
      history.push(
        `/area-riservata/amministrazione/gestione-utenti/${response[0].id}/modifica?trusted=true`,
      );
    } else {
      setIsNew(true);
      setModalCheck(false);
      setModalShow(true);
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getUtenti(0, 10, 1);
  }

  // Function collegata al servizio per creare un nuovo utente
  async function nuovoUtente() {
    if (validateCreaUtente(stateUtente, errors)) {
      utenteService.creaUtente(stateUtente)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalShow(false);
          refresh();
          if (res.data?.sequ_k_utente_trusted) {
            history.push(
              `/area-riservata/amministrazione/gestione-utenti/${res.data.sequ_k_utente_trusted}/modifica?trusted=true`,
            );
          }
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  // Function per gestire la modifica della modale per l'aggiungi di un nuovo utente
  const onChange = (e) => {
    const { name, value } = e.target;
    setStateUtente({ ...stateUtente, [name]: name === 'codi_id_fiscale' ? value.toUpperCase() : value });
  };

  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      sortField: type === 'sort' ? sortField : 'id',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getUtenti(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  function onChangeFiltri(e) {
    const { id, value } = e.target;
    switch (id) {
      case 'trusted': {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: value === 'true',
        }));
        break;
      }
      case 'fk_codi_tipo_connettore': {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: parseInt(value, 10),
        }));
        break;
      }
      default: {
        setFiltri((prevFiltri) => ({
          ...prevFiltri,
          [id]: value,
        }));
        break;
      }
    }
  }

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getUtenti(offset, 10, storage.page, storage.filtri);
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  return (
    <>
      <TitlePage
        title="Gestione Utenti"
        subtitle="È possibile ricercare e gestire gli utenti"
        showRefresh
        showAdd
        functionRefresh={() => {
          getUtenti(0, 10, 1);
        }}
        functionAdd={() => setModalCheck(true)}
      />
      <FormGestione
        filtri={filtri}
        setFiltri={setFiltri}
        onChange={onChangeFiltri}
        getUtenti={getUtenti}
      />
      {utenti.length > 0 ? (
        <Table
          title="Elenco utenti"
          data={utenti}
          fields={
            filtri.profondita_storica === 'true'
              ? fieldsProfonditaStorica : fieldsNoProfonditaStorica
          }
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="ordinamento"
        />
      ) : (
        <EmptyState marginTop="3rem" />
      )}
      <ModaleCentroVerticale
        modalTitle="Verifica esistenza Identità Utente"
        modalBody={(
          <FormGestioneUtenti
            stateUtente={stateUtente}
            onChange={onChange}
            errors={errors}
          />
        )}
        buttonType="submit"
        show={modalCheck}
        onConfirm={() => getUtenteByIdentificativo(stateUtente.codi_id_fiscale)}
        labelConfirm="Verifica"
        onClose={() => { setModalCheck(false); }}
      />
      <ModaleCentroVerticale
        modalTitle="Crea un nuovo Utente"
        modalBody={(
          <FormGestioneUtenti
            stateUtente={stateUtente}
            onChange={onChange}
            errors={errors}
            isNew={isNew}
          />
        )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => nuovoUtente()}
        labelConfirm="Salva e aggiungi Identità Associate"
        onClose={() => { setModalShow(false); }}
      />
    </>
  );
}

export default GestisciUtenti;
