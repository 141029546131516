import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Col, Container, Form, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../../utils/Context';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import Table from '../../../../components/Shared/Table';
import fieldsCompetenzeDemoAssessmentCompleto from './fieldsAssessmentCompleto';
import demoAssessmentCompleto from '../data/demoAssessmentCompleto';
import history from '../../../../utils/history';

function DemoAssessmentCompleto() {
  const [context, setContext] = useContext(Context);
  const [gradingList, setGradingList] = useState([]);

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService.getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getGrading(0, 1000, {}); // IMPOSTATO LIMIT 1000
  }, []);

  return (
    <Container className="p-0">
      <div className="w-100">
        <div className="mb-3">
          <h1>
            {' '}
            Analisi Esiti -
            {' '}
            {demoAssessmentCompleto.desc_titolo}
            {' '}
          </h1>
        </div>
        <Row className="position-relative mb-2">
          <Col className="col-md-4">
            <Button variant="grey" className=" float-left" onClick={() => { history.goBack(); }}>
              {' '}
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'arrow-left']} />
              {' '}
              INDIETRO
              {' '}
            </Button>
          </Col>
          <Col>
            <Row className="float-end">
              <Col className="col-lg-auto text-end">
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id="tooltip-disabled">
                      Funzione per riaprire la fase di eterovalutazione,
                      tale funzionalità è legata all&apos;identità responsabile.
                    </Tooltip>
                )}
                >
                  <span className="d-inline-block">
                    <Button
                      style={{ color: 'white', pointerEvents: 'none' }}
                      variant="primary"
                      disabled
                    >
                      {' '}
                      <FontAwesomeIcon aria-hidden="true" icon={['fas', 'reply']} />
                      {' '}
                      RIAPRI ETEROVALUTAZIONE
                      {' '}
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Form.Group as={Col} lg controlId="etero_assessment">
        <Form.Label>
          Ciclo di Assessment
        </Form.Label>
        <Form.Control
          name="fk_sequ_ciclo_assesment_d_ciclo_assesment.desc_titolo"
          value={demoAssessmentCompleto.desc_titolo}
          disabled
        />
        <Form.Label>
          Stato
        </Form.Label>
        <Form.Control
          name="fk_codi_stato_d_stato_assesment.desc_stato"
          value={demoAssessmentCompleto.desc_stato}
          disabled
        />
        <Form.Label>
          Dipendente
        </Form.Label>
        <Form.Control
          name="utente_dipendente"
          value={`${demoAssessmentCompleto.utente_dipendente.desc_nome} ${demoAssessmentCompleto.utente_dipendente.desc_cognome}`}
          disabled
        />
        <Form.Label>
          Responsabile
        </Form.Label>
        <Form.Control
          name="utente_responsabile"
          value={`${demoAssessmentCompleto.utente_responsabile.desc_nome} ${demoAssessmentCompleto.utente_responsabile.desc_cognome}`}
          disabled
        />
        <Form.Label>
          Profilo di ruolo
        </Form.Label>
        <Form.Control
          name="fk_sequ_profilo_ruolo_d_profilo_ruolo.desc_titolo"
          value={demoAssessmentCompleto.fk_sequ_profilo_ruolo_d_profilo_ruolo.desc_titolo}
          disabled
        />
        {gradingList.length > 0 && (
          <>
            <Form.Label>
              Etero Valutazione
            </Form.Label>
            <Table
              data={demoAssessmentCompleto.rel_competenza_assessment}
              fields={fieldsCompetenzeDemoAssessmentCompleto(
                gradingList,
              )}
              keyField="competenze_list_etero_assessment"
            />
          </>
        )}
      </Form.Group>
    </Container>
  );
}

export default DemoAssessmentCompleto;
