import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { controlla } from '../../../utils/verificaPermessi';

const fieldsCompetenze = (onDelete, onView, onModify, idAmministrazione) => (
  [
    {
      dataField: 'desc_codice',
      text: 'Codice',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'codi_tipo_competenza',
      text: 'Tipo',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '50%' }),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_competenza}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView({
              id: row.sequ_k_competenza,
              codice: row.desc_codice,
              idAmministrazione: row.fk_codi_amministrazione,
            })}
          />
          {(controlla('X24') && row.fk_codi_amministrazione === idAmministrazione) && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title="Modifica"
            id={`Modifica${row.sequ_k_competenza}`}
            style={{ color: themeColors.primary }}
            onClick={() => onModify(true, { id: row.sequ_k_competenza, codice: row.desc_codice })}
          />
          )}
          {(controlla('X24') && row.fk_codi_amministrazione === idAmministrazione) && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash']}
            size="lg"
            title="Elimina"
            id={`Visualizza${row.sequ_k_competenza}`}
            style={{ color: themeColors.danger }}
            onClick={() => onDelete({ id: row.sequ_k_competenza, codice: row.desc_codice })}
          />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsCompetenze;
