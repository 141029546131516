/* eslint-disable max-len */
import { Router, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import PageNotFound from './pages/NotFound';
import HomePage from './pages';
import Home from './pages/area-riservata';
import Login from './pages/pagina-login';
import history from './utils/history';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout/index';
import GestisciUtenti from './pages/area-riservata/amministrazione/gestione-utenti';
import TornaSu from './components/TornaSu';
import UserInformation from './pages/area-riservata/account';
import Amministrazione from './pages/area-riservata/amministrazione';
// import GestioneRuoli from './pages/area-riservata/amministrazione/gestione-ruolo';
// import GestioneIdentita from './pages/area-riservata/amministrazione/gestione-identita';
import SceltaIdentita from './pages/area-riservata/scelta-identita';
// import GestionePiattaforma from './pages/area-riservata/amministrazione/gestione-piattaforma';
// import GestioneTagRuoli from './pages/area-riservata/amministrazione/gestione-tag-ruolo';
// import AggiungiTagRuolo from './pages/area-riservata/amministrazione/gestione-tag-ruolo/aggiungi';
// import FormNuovoRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/inserimento';
// import FormModificaRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/_id/modifica';
// import FormDettaglioRuolo from './pages/area-riservata/amministrazione/gestione-ruolo/_id/dettaglio';
// import GestioneTagIdentita from './pages/area-riservata/amministrazione/gestione-tag-identita';
// import AggiungiTagIdentita from './pages/area-riservata/amministrazione/gestione-tag-identita/aggiungi';
// import AggiungIdentita from './pages/area-riservata/amministrazione/gestione-identita/aggiungi';
// import DettaglioIdentita from './pages/area-riservata/amministrazione/gestione-identita/_id/dettaglio';
// import ModificaIdentita from './pages/area-riservata/amministrazione/gestione-identita/_id/modifica';
import DettaglioUtentePerAmministratore from './pages/area-riservata/amministrazione/gestione-utenti/dettaglio';
import ModificaUtentePerAmministratore from './pages/area-riservata/amministrazione/gestione-utenti/dettaglio/modifica';
import GestioneRichiesteIdentita from './pages/area-riservata/amministrazione/gestione-richieste-identita';
import DettaglioRichiestaIdentitaAmministratore from './pages/area-riservata/amministrazione/gestione-richieste-identita/_id/dettaglio';
import GestioneRichiesteIdentitaPerUtente from './pages/area-riservata/richiesta-identita';
import DettaglioRichiestaIdentitaPerUtente from './pages/area-riservata/richiesta-identita/_id/dettaglio';
import NuovaRichiestaIdentitaPerUtente from './pages/area-riservata/richiesta-identita/aggiungi';
import LoginSpid from './pages/login';
import ErrorPage from './pages/error';
import ScrollToTop from './utils/ScrollToTop';
import Notifiche from './pages/area-riservata/notifiche';
import ModificaRichiestaIdentitaPerUtente from './pages/area-riservata/richiesta-identita/_id/modifica';
import AttivaRichiestaAccreditamentoByHash from './pages/attiva-richiesta-accreditamento/hash';
import Progetto from './pages/progetto';
import Approfondimenti from './pages/approfondimenti';
import AreaAmministrazioni from './pages/area-amministrazioni';
import AreaContrattuale from './pages/area-riservata/area-contrattuale';
import FamiglieProfessionali from './pages/area-riservata/famiglie-professionali';
import AmbitiRuolo from './pages/area-riservata/ambiti-ruolo';
import DimensioniProfessionali from './pages/area-riservata/dimensioni-professionali';
import AreeCompetenze from './pages/area-riservata/aree-competenze';
import Competenze from './pages/area-riservata/competenze';
import Grading from './pages/area-riservata/grading';
import ProfiliRuolo from './pages/area-riservata/profili-ruolo';
import ProfiliProfessionali from './pages/area-riservata/profili-professionali';
import ProfiloProfessionaleDettaglio from './pages/area-riservata/profili-professionali/dettaglio';
import ProfiloRuoloDettaglio from './pages/area-riservata/profili-ruolo/dettaglio';
import CicliAssessment from './pages/area-riservata/cicli-assessment';
import AutoAssessmentDettaglio from './pages/area-riservata/auto-assessment/dettaglio';
import AutoAssessment from './pages/area-riservata/auto-assessment';
import EteroAssessment from './pages/area-riservata/etero-assessment';
import EteroAssessmentDettaglioDipendenti from './pages/area-riservata/etero-assessment/dettaglioDipendenti';
import EteroAssessmentDettaglio from './pages/area-riservata/etero-assessment/dettaglio';
import Chat from './pages/area-riservata/chat';
import AnalisiEsitiAssessment from './pages/area-riservata/analisi-esiti';
import AnalisiEsitiAssessmentDettaglioDipendenti from './pages/area-riservata/analisi-esiti/dettaglioDipendenti';
import AnalisiEsitiAssessmentDettaglio from './pages/area-riservata/analisi-esiti/dettaglio';
import ApprofondimentiDettaglio from './pages/approfondimenti/dettaglio';
import FaqPubblica from './pages/faq';
import DichiarazioneAccessibilita from './pages/dichiarazione-accessibilità';
import DimensioneProfessionaleDettaglio from './pages/area-riservata/dimensioni-professionali/dettaglio';
import FamigliaProfessionaliDettaglio from './pages/area-riservata/famiglie-professionali/dettaglio';
import ElencoEnti from './pages/area-riservata/amministrazione/elenco-enti';
import Template from './pages/area-riservata/template';
import GestioneTemplate from './pages/area-riservata/template/gestione-template';
import VisualizzaTemplate from './pages/area-riservata/template/visualizza-template';
import VisualizzaStatistiche from './pages/area-riservata/template/visualizza-statistiche';
import Questionari from './pages/area-riservata/questionari';
import CompilaQuestionario from './pages/area-riservata/questionari/compilaQuestionario';
import VisualizzaQuestionario from './pages/area-riservata/questionari/visualizzaQuestionario';
import GestioneNews from './pages/area-riservata/gestione-news';
import DettaglioNews from './pages/area-riservata/gestione-news/dettaglio';
import AggiungiNews from './pages/area-riservata/gestione-news/aggiungi';
import ModificaNews from './pages/area-riservata/gestione-news/modifica';
import GestioneNewsEsterne from './pages/area-riservata/gestione-news-esterne';
import DettaglioNewsEsterna from './pages/area-riservata/gestione-news-esterne/dettaglio';
import AggiungiNewsEsterna from './pages/area-riservata/gestione-news-esterne/aggiungi';
import ModificaNewsEsterna from './pages/area-riservata/gestione-news-esterne/modifica';
import GestioneFaq from './pages/area-riservata/gestione-faq';
import GestioneFaqPubbliche from './pages/area-riservata/gestione-faq-esterne';
import AggiungiFaqPubblica from './pages/area-riservata/gestione-faq-esterne/aggiungi';
import ModificaFaqPubblica from './pages/area-riservata/gestione-faq-esterne/modifica';
import AggiungiFaq from './pages/area-riservata/gestione-faq/aggiungi';
import ModificaFaq from './pages/area-riservata/gestione-faq/modifica';
import ConfermaInvioQuestionario from './pages/questionari/conferma';
import AccettazioneTemplateEsterno from './pages/questionari/_idQuestionario';
import CompilaQuestionarioEsterno from './pages/questionari/compila-questionario';
import GestioneUtentiMassiva from './pages/area-riservata/amministrazione/gestione-utenti-massiva';
import DettaglioUtentiMassivo from './pages/area-riservata/amministrazione/gestione-utenti-massiva/dettaglio';
import ReportingSistema from './pages/area-riservata/statistiche/index/ReportingSistema';
import ReportingMatrice from './pages/area-riservata/statistiche/index/ReportingMatrice';
import ReportingAssessment from './pages/area-riservata/statistiche/index/ReportingAssessment';
import DemoAssessment from './pages/area-riservata/demo-assessment/ciclo-assessment';
import DemoEteroAssessment from './pages/area-riservata/demo-assessment/etero-assessment';
import DemoAssessmentCompleto from './pages/area-riservata/demo-assessment/assessment-completo';
import DemoDipendentiAssessment from './pages/area-riservata/demo-assessment/elenco-assessment/elenco';
import DemoElencoAssessment from './pages/area-riservata/demo-assessment/elenco-assessment';
import DemoAutoAssessment from './pages/area-riservata/demo-assessment/auto-assesment';

function App() {
  library.add(fab, fas, far);
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Router history={history}>
        <Switch>
          <PublicLayout path="/" exact component={HomePage} />
          <PublicLayout path="/pagina-login" exact component={Login} />
          <PublicLayout path="/progetto" exact component={Progetto} />
          <PublicLayout path="/approfondimenti" exact component={Approfondimenti} />
          <PublicLayout path="/approfondimenti/:idNews" exact component={ApprofondimentiDettaglio} />
          <PublicLayout path="/faq" exact component={FaqPubblica} />
          <PublicLayout path="/dichiarazione-accessibilita" exact component={DichiarazioneAccessibilita} />
          <PublicLayout path="/area-amministrazioni" exact component={AreaAmministrazioni} />
          <PublicLayout path="/login" exact component={LoginSpid} />
          <PublicLayout path="/error" exact component={ErrorPage} />
          <PublicLayout path="/attiva-richiesta-accreditamento/:hash" exact component={AttivaRichiestaAccreditamentoByHash} />

          {/* INIZIO PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA  */}
          <PublicLayout path="/questionari/conferma" exact component={ConfermaInvioQuestionario} />
          <PublicLayout path="/questionari/:idTemplate" exact component={AccettazioneTemplateEsterno} />
          <PublicLayout path="/questionari/compila-questionario/:idQuestionario" exact component={CompilaQuestionarioEsterno} />
          {/* FINE PATH COMPILAZIONE QUESTIONARI AREA PUBBLICA  */}

          {/* INIZIO PATH AREA RISERVATA  */}
          <PrivateLayout path="/area-riservata" exact component={Home} />
          <PrivateLayout path="/area-riservata/account" exact component={UserInformation} />
          <PrivateLayout path="/area-riservata/scelta-identita" exact component={SceltaIdentita} />
          <PrivateLayout path="/area-riservata/notifiche" exact component={Notifiche} />
          <PrivateLayout path="/area-riservata/area-contrattuale" exact component={AreaContrattuale} />
          <PrivateLayout path="/area-riservata/famiglie-professionali" exact component={FamiglieProfessionali} />
          <PrivateLayout path="/area-riservata/famiglie-professionali/nuovo" exact component={FamigliaProfessionaliDettaglio} />
          <PrivateLayout path="/area-riservata/famiglie-professionali/:idFamigliaProfessionale" exact component={FamigliaProfessionaliDettaglio} />
          <PrivateLayout path="/area-riservata/dimensioni-professionali" exact component={DimensioniProfessionali} />
          <PrivateLayout path="/area-riservata/dimensioni-professionali/nuovo" exact component={DimensioneProfessionaleDettaglio} />
          <PrivateLayout path="/area-riservata/dimensioni-professionali/:idDimensioneProfessionale" exact component={DimensioneProfessionaleDettaglio} />
          <PrivateLayout path="/area-riservata/aree-competenze" exact component={AreeCompetenze} />
          <PrivateLayout path="/area-riservata/ambiti-ruolo" exact component={AmbitiRuolo} />
          <PrivateLayout path="/area-riservata/competenze/:tipoCompetenza" exact component={Competenze} />
          <PrivateLayout path="/area-riservata/grading" exact component={Grading} />

          {/* INIZIO PATH PROFILI DI RUOLO */}
          <PrivateLayout path="/area-riservata/profili-ruolo" exact component={ProfiliRuolo} />
          <PrivateLayout path="/area-riservata/profili-ruolo/:idProfiloRuolo" exact component={ProfiloRuoloDettaglio} />
          {/* FINE PATH PROFILI DI RUOLO */}

          {/* INIZIO PATH PROFILI PROFESSIONALI */}
          <PrivateLayout path="/area-riservata/profili-professionali" exact component={ProfiliProfessionali} />
          <PrivateLayout path="/area-riservata/profili-professionali/:idProfiloProfessionale" exact component={ProfiloProfessionaleDettaglio} />
          {/* FINE PATH PROFILI PROFESSIONALI */}

          {/* INIZIO PATH ASSESSMENT */}
          <PrivateLayout path="/area-riservata/assessment" exact component={CicliAssessment} />
          <PrivateLayout path="/area-riservata/analisi-assessment" exact component={AnalisiEsitiAssessment} />
          <PrivateLayout path="/area-riservata/analisi-assessment/:idCicloAssessment" exact component={AnalisiEsitiAssessmentDettaglioDipendenti} />
          <PrivateLayout path="/area-riservata/analisi-assessment/:idCicloAssessment/:idUser" exact component={AnalisiEsitiAssessmentDettaglio} />
          <PrivateLayout path="/area-riservata/auto-assessment" exact component={AutoAssessment} />
          <PrivateLayout path="/area-riservata/auto-assessment/:idCicloAssessment" exact component={AutoAssessmentDettaglio} />
          <PrivateLayout path="/area-riservata/etero-assessment" exact component={EteroAssessment} />
          <PrivateLayout path="/area-riservata/etero-assessment/:idCicloAssessment" exact component={EteroAssessmentDettaglioDipendenti} />
          <PrivateLayout path="/area-riservata/etero-assessment/:idCicloAssessment/:idUser" exact component={EteroAssessmentDettaglio} />
          {/* FINE PATH ASSESSMENT */}

          {/* INIZIO DEMO ASSESSMENT */}
          <PrivateLayout path="/area-riservata/demo-assessment/ciclo-assessment" exact component={DemoAssessment} />
          <PrivateLayout path="/area-riservata/demo-assessment/elenco-assessment" exact component={DemoElencoAssessment} />
          <PrivateLayout path="/area-riservata/demo-assessment/elenco-assessment/elenco" exact component={DemoDipendentiAssessment} />
          <PrivateLayout path="/area-riservata/demo-assessment/auto-assessment" exact component={DemoAutoAssessment} />
          <PrivateLayout path="/area-riservata/demo-assessment/etero-assessment" exact component={DemoEteroAssessment} />
          <PrivateLayout path="/area-riservata/demo-assessment/assessment-completo" exact component={DemoAssessmentCompleto} />
          {/* FINE DEMO ASSESSMENT */}

          {/* INIZIO PATH TEMPLATE */}
          <PrivateLayout path="/area-riservata/template" exact component={Template} />
          <PrivateLayout path="/area-riservata/template/gestione-template/:idTemplate" exact component={GestioneTemplate} />
          <PrivateLayout path="/area-riservata/template/visualizza-template/:idTemplate" exact component={VisualizzaTemplate} />
          <PrivateLayout path="/area-riservata/template/visualizza-statistiche/:idTemplate" exact component={VisualizzaStatistiche} />
          {/* FINE PATH TEMPLATE */}

          {/* INIZIO PATH QUESTIONARI */}
          <PrivateLayout path="/area-riservata/questionari" exact component={Questionari} />
          <PrivateLayout path="/area-riservata/questionari/compila-questionario/:idQuestionario" exact component={CompilaQuestionario} />
          <PrivateLayout path="/area-riservata/questionari/visualizza-questionario/:idQuestionario" exact component={VisualizzaQuestionario} />
          {/* FINE PATH QUESTIONARI */}

          {/* INIZIO STATISTICHE */}
          <PrivateLayout path="/area-riservata/statistiche-sistema-professionale" exact component={ReportingSistema} />
          <PrivateLayout path="/area-riservata/statistiche-matrice-profili-professionali" exact component={ReportingMatrice} />
          <PrivateLayout path="/area-riservata/statistiche-assessment" exact component={ReportingAssessment} />
          {/* FINE STATISTICHE */}

          {/* INIZIO PATH AMMINISTRAZIONE */}
          <PrivateLayout path="/area-riservata/amministrazione" exact component={Amministrazione} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti" exact component={GestisciUtenti} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti/:idUtente/dettaglio" exact component={DettaglioUtentePerAmministratore} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti/:idUtente/modifica" exact component={ModificaUtentePerAmministratore} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti-massiva" exact component={GestioneUtentiMassiva} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-utenti-massiva/:idBatch/dettaglio" exact component={DettaglioUtentiMassivo} />
          {/* <PrivateLayout path="/area-riservata/amministrazione/gestione-identita" exact component={GestioneIdentita} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/aggiungi" exact component={AggiungIdentita} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/:idIdentita/dettaglio" exact component={DettaglioIdentita} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-identita/:idIdentita/modifica" exact component={ModificaIdentita} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-ruolo" exact component={GestioneTagRuoli} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-ruolo/aggiungi" exact component={AggiungiTagRuolo} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-identita" exact component={GestioneTagIdentita} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-tag-identita/aggiungi" exact component={AggiungiTagIdentita} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo" exact component={GestioneRuoli} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/inserimento" exact component={FormNuovoRuolo} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/:idRuolo/modifica" exact component={FormModificaRuolo} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-ruolo/:idRuolo/dettaglio" exact component={FormDettaglioRuolo} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-piattaforma" exact component={GestionePiattaforma} /> */}
          <PrivateLayout path="/area-riservata/amministrazione/gestione-richieste-identita" exact component={GestioneRichiesteIdentita} />
          <PrivateLayout path="/area-riservata/amministrazione/gestione-richieste-identita/:idRichiesta" exact component={DettaglioRichiestaIdentitaAmministratore} />
          <PrivateLayout path="/area-riservata/amministrazione/elenco-enti" exact component={ElencoEnti} />
          {/* FINE PATH AMMINISTRAZIONE */}

          {/* INIZIO PATH RICHIESTA IDENTITA */}
          <PrivateLayout path="/area-riservata/richiesta-identita" exact component={GestioneRichiesteIdentitaPerUtente} code="Y20" />
          <PrivateLayout path="/area-riservata/richiesta-identita/aggiungi" exact component={NuovaRichiestaIdentitaPerUtente} code="Y21" />
          <PrivateLayout path="/area-riservata/richiesta-identita/:idRichiesta" exact component={DettaglioRichiestaIdentitaPerUtente} code="Y22" />
          <PrivateLayout path="/area-riservata/richiesta-identita/:idRichiesta/modifica" exact component={ModificaRichiestaIdentitaPerUtente} />
          {/* FINE PATH RICHIESTA IDENTITA */}

          {/* INIZIO PATH NEWS */}
          <PrivateLayout path="/area-riservata/news" exact component={GestioneNews} />
          <PrivateLayout path="/area-riservata/news/dettaglio/:id" exact component={DettaglioNews} />
          <PrivateLayout path="/area-riservata/gestione-news" exact component={GestioneNews} />
          <PrivateLayout path="/area-riservata/gestione-news/modifica/:idNews" exact component={ModificaNews} />
          <PrivateLayout path="/area-riservata/gestione-news/aggiungi" exact component={AggiungiNews} />
          <PrivateLayout path="/area-riservata/gestione-news/dettaglio/:id" exact component={DettaglioNews} />
          <PrivateLayout path="/area-riservata/gestione-news-esterne" exact component={GestioneNewsEsterne} />
          <PrivateLayout path="/area-riservata/gestione-news-esterne/modifica/:idNews" exact component={ModificaNewsEsterna} />
          <PrivateLayout path="/area-riservata/gestione-news-esterne/aggiungi" exact component={AggiungiNewsEsterna} />
          <PrivateLayout path="/area-riservata/gestione-news-esterne/dettaglio/:id" exact component={DettaglioNewsEsterna} />
          {/* FINE PATH NEWS */}

          {/* INIZIO PATH FAQ E GESTIONE FAQ */}
          <PrivateLayout path="/area-riservata/faq" exact component={GestioneFaq} />
          <PrivateLayout path="/area-riservata/gestione-faq" exact component={GestioneFaq} />
          <PrivateLayout path="/area-riservata/gestione-faq/aggiungi" exact component={AggiungiFaq} />
          <PrivateLayout path="/area-riservata/gestione-faq/modifica/:idFaq" exact component={ModificaFaq} />
          <PrivateLayout path="/area-riservata/gestione-faq-esterne" exact component={GestioneFaqPubbliche} />
          <PrivateLayout path="/area-riservata/gestione-faq-esterne/aggiungi" exact component={AggiungiFaqPubblica} />
          <PrivateLayout path="/area-riservata/gestione-faq-esterne/modifica/:idFaq" exact component={ModificaFaqPubblica} />
          {/* FINE PATH FAQ E GESTIONE FAQ */}

          {/* INIZIO PATH CHAT */}
          <PrivateLayout path="/area-riservata/chat" exact component={Chat} />
          {/* FINE PATH CHAT */}

          <PrivateLayout path="/area-riservata/*" component={PageNotFound} />
          {/* FINE PATH AREA RISERVATA  */}
          <PublicLayout path="*" component={PageNotFound} />
        </Switch>
        {/* COMPONENTE CHE FA TORNARE SU AD OGNI CAMBIO PAGINA */}
        <ScrollToTop />
      </Router>
      {/* COMPONENTE GRAFICO PER TORNARE SU */}
      <TornaSu />
    </div>
  );
}

export default App;
