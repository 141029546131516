import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { formatoData } from '../../../utils/formatData';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';

const fields = () => [
  {
    dataField: 'sequ_k_richiesta',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'desc_msg_richiesta',
    text: 'Richiesta',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '45%' }),
    formatter: (e) => e || 'Nessun messaggio di richiesta inserito',
  },
  {
    dataField: 'data_invio_richiesta',
    text: 'Data invio',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_ultima_modifica',
    text: 'Data ultima modifica',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'desc_stato',
    text: 'Stato',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>

        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'search']}
          size="lg"
          title="Visualizza"
          id={`Visualizza${row.sequ_k_richiesta}`}
          style={{ color: themeColors.primary }}
          onClick={() => history.push(`/area-riservata/richiesta-identita/${row.sequ_k_richiesta}`)}
        />
        {controlla('X20') && row.codi_stato_richiesta === 1 ? (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title="Modifica"
            id={`Modifica${row.sequ_k_richiesta}`}
            style={{ color: themeColors.primary }}
            onClick={() => history.push(`/area-riservata/richiesta-identita/${row.sequ_k_richiesta}/modifica`)}
          />
        ) : null }
        {controlla('X20') && row.codi_stato_richiesta === 1 ? (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash']}
            size="lg"
            title="Elimina"
            id={`Visualizza${row.sequ_k_richiesta}`}
            style={{ color: themeColors.danger }}
            onClick={() => history.push(`/area-riservata/richiesta-identita/${row.sequ_k_richiesta}?elimina=true`)}
          />
        ) : null }
      </div>

    ),
  },
];

export default fields;
