import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Card, Carousel, Col, Container, Row,
} from 'react-bootstrap';
import immagineRiVa from '../images/RiVaImage1.jpg';
import './index.css';
import { Context } from '../utils/Context';
import newsService from '../services/newsService';
import { formatoData } from '../utils/formatData';
import noImage from '../images/noImage.png';

const contatoriAmmArray = [
  {
    id: 1,
    title: 'Enti Locali',
    text: '26',
    href: '#',
  },
  {
    id: 2,
    title: 'Amministrazioni Centrali',
    text: '8',
    href: '#',
  },
  {
    id: 3,
    title: 'Regioni',
    text: '14',
    href: '#',
  },
  {
    id: 4,
    title: 'Università',
    text: '10',
    href: '#',
  },
];

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

function HomePage() {
  const [context, setContext] = useContext(Context);
  const [listaNewsEvidenza, setListaNewsEvidenza] = useState([]);

  // Function collegata al servizio per ottenere la lista delle News
  async function getListaNewsPubbliche(offset, limit) {
    try {
      const response = await newsService.getListaNewsPubbliche(null, offset, limit);
      setListaNewsEvidenza(response?.newsInEvidenza || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getListaNewsPubbliche(0, 1000); // IMPOSTATO LIMIT 1000
  }, []);

  return (
    <>
      <section>
        <Container className="p-0">
          <Row className="d-flex align-items-center justify-content-center">
            <img src={immagineRiVa} className="img-fluid w-100" alt="Immagine RiVa" />
          </Row>
        </Container>
      </section>

      <section className="mb-5 p-4" style={{ backgroundColor: '#f7f7f7' }}>
        <Container className="p-0">
          <Row className="d-flex">
            <Col xs={12} md={6}>
              <Card className="cardHome m-2 h-100">
                <Card.Body className="cardBodyGeneral cardBodySpecific">
                  <Card.Title className="fs1-5">
                    Il Progetto
                  </Card.Title>
                  <Card.Text>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                      <div>
                        <div style={{ fontSize: '1rem' }}>
                          Il portale RiVa nasce nell&apos;ambito del
                          {' '}
                          <b>
                            Progetto RiVa - La gestione strategica delle risorse umane
                            per creare Valore Pubblico
                          </b>
                          , affidato a Formez dal Dipartimento della Funzione Pubblica
                          e finanziato dal PNRR &quot;Sub-Riforma 2.3.1
                          Riforma del mercato del lavoro della PA&quot;.
                        </div>
                      </div>
                    </div>
                  </Card.Text>
                  <div className="d-flex justify-content-center">
                    <Button id="scopri" href="/progetto">
                      Leggi di piu
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} style={{ borderLeft: 'solid 2px lightgrey' }}>
              <Card className="cardHome m-2 h-100">
                <Card.Body className="cardBodyGeneral cardBodySpecific">
                  <div className="h-100 d-flex flex-column justify-content-between">
                    <div>
                      <Card.Title className="fs1-5 d-flex justify-content-center">
                        Come registrare le amministrazioni
                      </Card.Title>
                      <Card.Text>
                        Se sei il referente della tua amministrazione accedi alla pagina
                        per aderire all&lsquo;iniziativa
                      </Card.Text>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button id="scopri" href="/area-amministrazioni">
                        Scopri i passaggi
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {listaNewsEvidenza.length > 0 && (
      <section className="mb-5">
        <Row className="justify-content-center my-3">
          <Col xs={12} className="d-flex justify-content-center my-3">
            <h1>Notizie in evidenza</h1>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <Carousel className="w-75" fade>
              {listaNewsEvidenza.map((news, index) => (
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={news.pathMinioImmagine ? `${newsUrl}/news-esterne/immagine/${encodeURIComponent(news.pathMinioImmagine)}` : noImage}
                    alt={`Slide_${index}`}
                  />
                  <Carousel.Caption className="captionCarousel">
                    <h3>{news.titolo}</h3>
                    <div className="d-flex justify-content-between px-2">
                      <p className="text-muted m-0">
                        <strong>
                          {formatoData(news.dataUltimaModifica ?? news.dataCreazione)}
                        </strong>
                      </p>
                      <a
                        href={`/approfondimenti/${news._id}`}
                        title="Leggi di più"
                        className="fw-bold"
                      >
                        Leggi di più
                        <svg className="icon icon-sm"><use href="/navbar-icons/sprites.svg#it-arrow-right" /></svg>
                      </a>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </section>
      )}
      <section className="mb-5">
        <Container>
          <Row>
            <Col xs={12} md={4} className="d-flex">
              <Card className="cardHome">
                <Card.Body className="cardBodyGeneral">
                  <Card.Title className="fs2">Amministrazioni aderenti al progetto</Card.Title>
                  <Card.Text className="fs1">
                    Numero delle pubbliche amministrazioni che hanno aderito al progetto.
                  </Card.Text>
                  <Card.Subtitle className="cardSubtitle mb-2 text-muted">Dati Aggiornati al 03/06/2024</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={8}>
              <Row xs={2} md={2}>
                {contatoriAmmArray.map((element) => (
                  <Col key={element.id}>
                    <Card className="cardHome p-2">
                      <Card.Body className="cardBodyGeneral cardBodySpecific">
                        <Card.Title className="fs1-5">{element.title}</Card.Title>
                        <Card.Text className="fs3">{element.text}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HomePage;
