import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
  Container,
} from 'react-bootstrap';
import Select from 'react-select';
import { setItem } from '../../../../utils/storage';

function FiltriAssegnatiPerProfessionale({
  filtri,
  setFiltri,
  sequ_k_comparto,
  famiglieProfessionaliList,
  areeContrattualiList,
  getProfiliAssegnatiPerProfessionale,
}) {
  // Function per gestire la modifica dei filtri
  function onChangeFiltri(e, config) {
    if (config) {
      if (config.action === 'clear') {
        setFiltri({
          ...filtri, [config.name]: '',
        });
      } else {
        setFiltri({
          ...filtri, [config.name]: e?.desc_codice,
        });
      }
    }
  }

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label>
                  Area Contrattuale
                </Form.Label>
                <Select
                  id="area_contrattuale"
                  name="area_contrattuale"
                  options={areeContrattualiList}
                  placeholder="Filtra per area contrattuale"
                  getOptionLabel={(option) => option.desc_titolo}
                  getOptionValue={(option) => option.desc_codice}
                  value={areeContrattualiList?.filter(
                    (el) => el.desc_codice
                  === filtri.area_contrattuale,
                  )?.[0] || ' '}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Famiglia Professionale
                </Form.Label>
                <Select
                  id="famiglia_professionale"
                  name="famiglia_professionale"
                  options={famiglieProfessionaliList}
                  placeholder="Filtra per famiglia professionale"
                  getOptionLabel={(option) => option.desc_titolo}
                  getOptionValue={(option) => option.desc_codice}
                  value={famiglieProfessionaliList?.filter(
                    (el) => el.desc_codice
                  === filtri.famiglia_professionale,
                  )?.[0] || ' '}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                />
              </Form.Group>
            </Row>
          </Form>
          <Container style={{ padding: 0, textAlign: 'end' }}>
            <Button
              variant="outline-primary"
              onClick={() => {
                setItem('filtri', JSON.stringify(filtri));
                getProfiliAssegnatiPerProfessionale(sequ_k_comparto, 'profili-assegnati-profilo-professionale', null, null, null, filtri);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              AGGIORNA
            </Button>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriAssegnatiPerProfessionale;
