/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import {
  Button, Card, Col, Container, Row, Table,
} from 'react-bootstrap';
import ModaleRisposte from '../modali/ModaleRisposte';

function CardGroupStatistiche({
  sezioni,
  statistiche,
  numQuestionari,
}) {
  const [showModale, setShowModale] = useState(false);

  // Function che gestisce la chiusura della modale
  function handleClose() {
    setShowModale(false);
  }

  return (
    <Container className="p-0">
      <Row xs={1} md={1} className="g-4">
        {sezioni && Object.values(sezioni).map((sezione) => (
          <Col key={sezione.titolo}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <strong>{sezione.titolo}</strong>
                </Card.Title>
                <p>
                  <strong>Descrizione:</strong>
                  {' '}
                  {sezione.descrizione}
                </p>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Domanda</th>
                      <th>Tipologia</th>
                      <th>Possibili risposte</th>
                      <th>Statistica risposte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statistiche && Object.values(sezione?.template?.pages?.[0]?.elements).map(
                      (domanda) => (
                        <tr>
                          <td>{domanda.name}</td>
                          <td>{domanda.type}</td>
                          <td>
                            {domanda.choices && domanda.choices.map((opzione) => (
                              <div>
                                <p>
                                  {opzione.text}
                                  {' '}
                                  (
                                  {opzione.value}
                                  )
                                </p>
                              </div>
                            ))}
                          </td>
                          <td>
                            {domanda.type === 'text' && (
                            <>
                              <Button variant="grey" onClick={() => setShowModale(true)}>
                                VISUALIZZA RISPOSTE
                              </Button>
                              <ModaleRisposte
                                showModale={showModale}
                                handleClose={handleClose}
                                title={domanda.name}
                                body={statistiche?.[sezione.titolo]?.[domanda.name]}
                              />
                            </>
                            )}
                            {(domanda.type === 'checkbox' || domanda.type === 'radiogroup' || domanda.type === 'dropdown') && (
                            <div>
                              {domanda?.choices?.map((opzione) => (
                                statistiche?.[sezione.titolo]?.[domanda.name]?.[opzione.value] ? (
                                  <div>
                                    <p>
                                      {statistiche?.[sezione.titolo]
                                        ?.[domanda.name]?.[opzione.value]}
                                      {' '}
                                      /
                                      {numQuestionari}
                                      {' '}
                                      (
                                      {Number.parseFloat(
                                        statistiche?.[sezione.titolo]
                                          ?.[domanda.name]?.[opzione.value]
                                      / numQuestionari * 100,
                                      ).toFixed(2)}
                                      {' '}
                                      %
                                      )
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p>
                                      0/
                                      {numQuestionari}
                                      {' '}
                                      (
                                      {' '}
                                      0
                                      {' '}
                                      %
                                      )
                                    </p>
                                  </div>
                                )))}
                            </div>
                            )}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default CardGroupStatistiche;
