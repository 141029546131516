import api from '../utils/api';

const assessmentUrl = process.env.REACT_APP_ASSESSMENT_URL;

// function sezione: GENERAL
async function getStatoCicloAssessment() {
  return api.get(`${assessmentUrl}/general/stato-ciclo-assessment`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getStatoAssessment() {
  return api.get(`${assessmentUrl}/general/stato-assessment`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: ADMIN
async function nuovoCicloAssessment(cicloAssessment) {
  return api.post(`${assessmentUrl}/admin`, cicloAssessment)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getCicloAssessmentById(id) {
  return api.get(`${assessmentUrl}/admin/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function eliminaCicloAssessment(id) {
  return api.delete(`${assessmentUrl}/admin/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function modificaCicloAssessment(id, cicloAssessment) {
  return api.patch(`${assessmentUrl}/admin/${id}`, cicloAssessment)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function avviaCicloAssessment(id) {
  return api.patch(`${assessmentUrl}/admin/avvia/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function chiudiCicloAssessment(id) {
  return api.patch(`${assessmentUrl}/admin/chiudi/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getCicliAssessmentAdmin(filters, offset, limit) {
  let params = '';
  if (filters.desc_titolo) {
    params += `desc_titolo=${filters.desc_titolo}&`;
  }
  if (filters.fk_codi_stato) {
    params += `fk_codi_stato=${filters.fk_codi_stato}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${assessmentUrl}/admin/ciclo-assessment/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getAssessmentsAdmin(idCiclo, filters, offset, limit) {
  let params = '';
  if (filters.fk_codi_stato) {
    params += `fk_codi_stato=${filters.fk_codi_stato}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${assessmentUrl}/admin/assessments/${idCiclo}/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUserAssessmentAdmin(idCiclo, idUser) {
  return api.get(`${assessmentUrl}/admin/${idCiclo}/${idUser}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: USER
async function getCicloAssessmentUserById(idCicloAssessment) {
  return api.get(`${assessmentUrl}/user/${idCicloAssessment}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function salvaCicloAssessmentUser(idCicloAssessment, competenzeList) {
  return api.patch(`${assessmentUrl}/user/${idCicloAssessment}`, competenzeList)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function consolidaAutovalutazione(idCicloAssessment) {
  return api.patch(`${assessmentUrl}/user/consolida-autovalutazione/${idCicloAssessment}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUserProfiloRuolo() {
  return api.get(`${assessmentUrl}/user/profilo-ruolo`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getCicliAssessmentUser(filters, offset, limit) {
  let params = '';
  if (filters.desc_titolo) {
    params += `desc_titolo=${filters.desc_titolo}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${assessmentUrl}/user/ciclo-assessment/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// function sezione: RESPONSABILE
async function getListAssessmentResponsabileById(idCicloAssessment, offset, limit) {
  let params = '';
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${assessmentUrl}/responsabile/${idCicloAssessment}?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getUserResponsabileById(idCicloAssessment, idUser) {
  return api.get(`${assessmentUrl}/responsabile/${idCicloAssessment}/${idUser}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function salvaEteroValutazione(idCicloAssessment, idUser, competenzeList) {
  return api.patch(`${assessmentUrl}/responsabile/${idCicloAssessment}/${idUser}`, competenzeList)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function consolidaEteroValutazione(idCicloAssessment, idUser) {
  return api.patch(`${assessmentUrl}/responsabile/consolida-eterovalutazione/${idCicloAssessment}/${idUser}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function riapriEteroValutazione(idCicloAssessment, idUser) {
  return api.patch(`${assessmentUrl}/responsabile/riapri-eterovalutazione/${idCicloAssessment}/${idUser}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getCicliAssessmentResponsabile(filters, offset, limit) {
  let params = '';
  if (filters.desc_titolo) {
    params += `desc_titolo=${filters.desc_titolo}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${assessmentUrl}/responsabile/ciclo-assessment/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getReportAssessment() {
  return api.get(`${assessmentUrl}/report/assessment`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListSolleciti(idCicloAssessment, idDipendente) {
  return api.get(`${assessmentUrl}/responsabile/sollecito/${idCicloAssessment}/${idDipendente}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function nuovoSollecito(idCicloAssessment, idDipendente) {
  return api.post(`${assessmentUrl}/responsabile/sollecito/${idCicloAssessment}/${idDipendente}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function fissaColloquio(idAssessment, dataColloquio) {
  return api.put(`${assessmentUrl}/responsabile/colloquio/${idAssessment}`, dataColloquio)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function richiediColloquio(idAssessment) {
  return api.post(`${assessmentUrl}/user/colloquio/${idAssessment}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const AssessmentService = {
  getStatoCicloAssessment,
  getStatoAssessment,
  nuovoCicloAssessment,
  getCicloAssessmentById,
  eliminaCicloAssessment,
  modificaCicloAssessment,
  avviaCicloAssessment,
  chiudiCicloAssessment,
  getCicliAssessmentAdmin,
  getAssessmentsAdmin,
  getUserAssessmentAdmin,
  getCicloAssessmentUserById,
  salvaCicloAssessmentUser,
  consolidaAutovalutazione,
  getUserProfiloRuolo,
  getCicliAssessmentUser,
  getListAssessmentResponsabileById,
  getUserResponsabileById,
  salvaEteroValutazione,
  consolidaEteroValutazione,
  riapriEteroValutazione,
  getCicliAssessmentResponsabile,
  getReportAssessment,
  getListSolleciti,
  nuovoSollecito,
  fissaColloquio,
  richiediColloquio,
};

export default AssessmentService;
