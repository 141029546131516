import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Form } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import AssessmentService from '../../../../services/assessmentService';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../components/Shared/Table';
import fieldsCompetenzeAnalisiEsitiAssessment from './fieldsTable';
import history from '../../../../utils/history';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import ReactECharts from 'echarts-for-react';
import optionsRiepilogoGrafico from './optionsRiepilogoGrafico';

function AnalisiEsitiAssessmentDettaglio() {
  const [context, setContext] = useContext(Context);
  const { idCicloAssessment, idUser } = useParams();
  const [stateEteroAssessment, setStateEteroAssessment] = useState({});
  const [competenzeRiepilogo, setCompetenzeRiepilogo] = useState([]);
  const [gradingList, setGradingList] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  // function collegata al servizio di get del ciclo di assessment in cui fare l'eterovalutazione
  async function getUserAssessmentAdmin(cicloId, userId) {
    try {
      const response = await AssessmentService.getUserAssessmentAdmin(cicloId, userId);
      setStateEteroAssessment({
        ...response,
        utente_dipendente: {
          ...response.utente_dipendente,
          ...response.utente_dipendente.dettaglio[0],
        },
        utente_responsabile: {
          ...response.utente_responsabile,
          ...response.utente_responsabile.dettaglio[0],
        },
        rel_competenza_assessment: response.d_rel_competenza_assesments.map(
          (competenza) => ({
            fk_sequ_competenza: competenza.fk_sequ_competenza,
            sequ_k_competenza_assesment: competenza.sequ_k_competenza_assesment,
            codi_grading_atteso: competenza.codi_grading_atteso,
            codi_grading_assegnato: competenza.codi_grading_assegnato,
            codi_grading_autovalutazione: competenza.codi_grading_autovalutazione,
            comp_desc_codice: competenza.fk_sequ_competenza_d_competenza.desc_codice,
            comp_desc_descrizione: competenza.fk_sequ_competenza_d_competenza.desc_descrizione,
            comp_tipo_competenza: competenza.fk_sequ_competenza_d_competenza.codi_tipo_competenza,
          }),
        ),
      });
      const competenzeTotali = response.d_rel_competenza_assesments;
      const gradingsCompetenze = competenzeTotali.map((el) => (
        {
          Competenza: `${el.fk_sequ_competenza_d_competenza.desc_codice} ${el.fk_sequ_competenza_d_competenza.desc_titolo}`,
          'Esito Assessment': el.codi_grading_assegnato - el.codi_grading_atteso,
        }
      ))
      setCompetenzeRiepilogo(gradingsCompetenze);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService.getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getGrading(0, 1000, {}); // IMPOSTATO LIMIT 1000
    getUserAssessmentAdmin(idCicloAssessment, idUser);
  }, []);
  return (
    <Container className="p-0">
      <TitlePage
        title="Analisi Esiti"
        showIndietro
        functionIndietro={() => history.goBack()}
        showGeneral
        functionGeneral={() => setModalShow(true)}
        iconGeneral="square-poll-horizontal"
        variantGeneral="primary"
        titleGeneral="RIEPILOGO GRAFICO"
      />
      <Form.Group as={Col} lg controlId="analisi_esiti_assessment">
        <Form.Label>
          Ciclo di Assessment
        </Form.Label>
        <Form.Control
          name="fk_sequ_ciclo_assesment_d_ciclo_assesment.desc_titolo"
          value={stateEteroAssessment?.fk_sequ_ciclo_assesment_d_ciclo_assesment?.desc_titolo}
          disabled
        />
        <Form.Label>
          Stato
        </Form.Label>
        <Form.Control
          name="fk_codi_stato_d_stato_assesment.desc_stato"
          value={stateEteroAssessment?.fk_codi_stato_d_stato_assesment?.desc_stato}
          disabled
        />
        <Form.Label>
          Dipendente
        </Form.Label>
        <Form.Control
          name="utente_dipendente"
          value={`${stateEteroAssessment?.utente_dipendente?.desc_nome} ${stateEteroAssessment?.utente_dipendente?.desc_cognome}`}
          disabled
        />
        <Form.Label>
          Responsabile
        </Form.Label>
        <Form.Control
          name="utente_responsabile"
          value={`${stateEteroAssessment?.utente_responsabile?.desc_nome} ${stateEteroAssessment?.utente_responsabile?.desc_cognome}`}
          disabled
        />
        <Form.Label>
          Profilo di ruolo
        </Form.Label>
        <Form.Control
          name="fk_sequ_profilo_ruolo_d_profilo_ruolo.desc_titolo"
          value={stateEteroAssessment?.fk_sequ_profilo_ruolo_d_profilo_ruolo?.desc_titolo}
          disabled
        />
        {(gradingList.length > 0 && stateEteroAssessment?.rel_competenza_assessment?.length > 0)
        && (
        <>
          <Form.Label>
            Analisi degli Esiti
          </Form.Label>
          <Table
            data={stateEteroAssessment.rel_competenza_assessment}
            fields={fieldsCompetenzeAnalisiEsitiAssessment(
              stateEteroAssessment.rel_competenza_assessment,
              gradingList,
              stateEteroAssessment.fk_codi_stato,
            )}
            keyField="competenze_list_analisi_esiti_assessment"
          />
        </>
        )}
      </Form.Group>
      <ModaleCentroVerticale
        modalTitle="Riepilogo Analisi Esiti"
        modalBody={(
          <ReactECharts
            option={optionsRiepilogoGrafico(competenzeRiepilogo)}
            style={{ height: 700 }}
          />
        )}
        size='xl'
        show={modalShow}
        disabledConfirm
        onClose={() => setModalShow(false)}
      />
    </Container>
  );
}

export default AnalisiEsitiAssessmentDettaglio;
