import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { formatoData } from '../../../../utils/formatData';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';

const fields = () => [
  {
    dataField: 'sequ_k_richiesta',
    text: 'ID',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '5%' }),
  },
  {
    dataField: 'codi_id_fiscale',
    text: 'CF Richiedente',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_nome',
    text: 'Nome richiedente',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_cognome',
    text: 'Cognome richiedente',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'desc_msg_richiesta',
    text: 'Richiesta',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => `${e && e.substring(0, 30)}...`,
  },
  {
    dataField: 'data_invio_richiesta',
    text: 'Data invio',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_ultima_modifica',
    text: 'Data ultima modifica',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'desc_stato',
    text: 'Stato',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (

      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
      }}
      >

        {controlla('X18') && (row.codi_stato_richiesta === 2 || row.codi_stato_richiesta === 3) && (
        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'cogs']}
          size="lg"
          title="Gestisci"
          id={`Gestisci${row.sequ_k_richiesta}`}
          style={{ color: themeColors.primary }}
          onClick={() => history.push(`/area-riservata/amministrazione/gestione-richieste-identita/${row.sequ_k_richiesta}`)}
        />
        ) }
        {controlla('X18') && (row.codi_stato_richiesta === 4 || row.codi_stato_richiesta === 5 || row.codi_stato_richiesta === 6) && (
        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'search']}
          size="lg"
          title="Visualizza"
          id={`Visualizza${row.sequ_k_richiesta}`}
          style={{ color: themeColors.primary }}
          onClick={() => history.push(`/area-riservata/amministrazione/gestione-richieste-identita/${row.sequ_k_richiesta}`)}
        />
        ) }
      </div>

    ),
  },
];

export default fields;
