import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';
import { optionsStatoElaborazione, optionsTipoBatch } from './constants/optionsBatch';
import history from '../../../../utils/history';

const fieldsUtentiMassivo = [
  {
    dataField: 'sequ_k_elaborazione_batch',
    text: 'Codice',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'codi_excel_minio',
    text: 'Nome File',
    classes: 'column-data',
    headerStyle: () => ({ width: '40%' }),
  },
  {
    dataField: 'codi_stato_elaborazione',
    text: 'Stato Elaborazione',
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (
      optionsStatoElaborazione.filter(
        (el) => el.codi_stato_elaborazione === row.codi_stato_elaborazione,
      )[0]?.stato_elaborazione
    ),
  },
  {
    dataField: 'codi_tipo_batch',
    text: 'Tipo Caricamento',
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (
      optionsTipoBatch.filter(
        (el) => el.codi_tipo_batch === row.codi_tipo_batch,
      )[0]?.tipo_batch
    ),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '10%' }),
    formatter: (e, row) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'search']}
          title={`Visualizza caricamento ${row.sequ_k_elaborazione_batch}`}
          size="lg"
          id={`visualizza-${row.sequ_k_elaborazione_batch}`}
          style={{ color: themeColors.primary }}
          className="me-2"
          onClick={() => history.push(
            `/area-riservata/amministrazione/gestione-utenti-massiva/${row.sequ_k_elaborazione_batch}/dettaglio`,
          )}
        />
      </div>
    ),
  },
];

export default fieldsUtentiMassivo;
