import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../../utils/Context';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormSelezioneCompetenze from '../../../../components/Form/SelezioneCompetenze';
import FormProfiliRuolo from '../FormProfiliRuolo';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';
import { getItem } from '../../../../utils/storage';

function ProfiloRuoloDettaglio() {
  const [context, setContext] = useContext(Context);
  const [gradingList, setGradingList] = useState([]);
  const [modalShowCts, setModalShowCts] = useState(false);
  const [competenzeListSelected, setCompetenzeListSelected] = useState([]);
  const [competenzeIniziali, setCompetenzeIniziali] = useState([]);
  const { idProfiloRuolo } = useParams();
  const [stateProfiloRuolo, setStateProfiloRuolo] = useState({});
  const [stateProfiloProfessionale, setStateProfiloProfessionale] = useState({});
  const [isModify, setIsModify] = useState(false);
  const [errors, setErrors] = useState({});
  const { state } = useLocation();
  const { idAmministrazione } = JSON.parse(getItem('identita'));

  // Validazione per la modifica di un profilo di ruolo
  const validateModificaProfiloRuolo = (stato, error) => {
    const temp = { ...error };
    temp.fk_sequ_competenze = stato.fk_sequ_competenze.some((el) => el.grading === 0);
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // Function per rimuovere le competenze duplicate.
  function removeDuplicatesComps(list) {
    const compsMap = {};
    list.forEach((el) => {
      if (
        !compsMap[el.fk_sequ_competenza] || compsMap[el.fk_sequ_competenza].grading < el.grading
      ) {
        compsMap[el.fk_sequ_competenza] = el;
      }
    });
    return Object.values(compsMap);
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, { sortField, sortOrder, data }) => {
    const newList = data;
    newList.sort((a, b) => {
      if (sortOrder === 'desc') {
        if (a[sortField] > b[sortField]) return -1;
        if (a[sortField] < b[sortField]) return 1;
        return 0;
      }
      if (a[sortField] < b[sortField]) return -1;
      if (a[sortField] > b[sortField]) return 0;
      return 0;
    });
  };

  // Function collegata al servizio per ottenere la lista dei gradings
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere il profilo professionale utilizzando l'id
  async function getProfiloProfessionaleById(idProfiloProfessionale) {
    try {
      const response = await SistemaProfessionaleService
        .getProfiloProfessionaleById(idProfiloProfessionale);
      const listCompetenzeMap = response.d_rel_profilo_prof_comps.map(
        (el) => ({
          ...el.fk_sequ_competenza_d_competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          grading_base: el.codi_grading_base,
          provenienza: el.provenienza,
        }),
      );
      setStateProfiloProfessionale({
        ...response,
        fk_sequ_competenze: removeDuplicatesComps(listCompetenzeMap),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere il profilo di ruolo utilizzando l'id
  async function getProfiloRuoloById(id) {
    try {
      const response = await SistemaProfessionaleService.getProfiloRuoloById(id);
      getProfiloProfessionaleById(response.fk_sequ_profilo_professionale);
      setStateProfiloRuolo({
        ...response,
        fk_sequ_competenze: response.d_rel_profilo_ruolo_comps.map(
          (el) => ({
            ...el.fk_sequ_competenza_d_competenza,
            fk_sequ_competenza: el.fk_sequ_competenza,
            grading: el.codi_grading,
          }),
        ),
      });
      const listCompetenzeMap = response.d_rel_profilo_ruolo_comps.map(
        (el) => ({
          ...el.fk_sequ_competenza_d_competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
        }),
      );
      setCompetenzeListSelected(listCompetenzeMap);
      setCompetenzeIniziali(listCompetenzeMap);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    setIsModify(state?.isModify ?? false);
    getProfiloRuoloById(idProfiloRuolo);
    getGrading(0, 1000, {});
  }, []);

  // function per effettuare il refresh della pagina
  function refresh() {
    getProfiloRuoloById(idProfiloRuolo);
    setIsModify(false);
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  }

  // Function per la modifica del profilo di ruolo
  const onChange = (e, config, row) => {
    if (config) {
      if (config.name === 'grading') {
        setStateProfiloRuolo((prevState) => ({
          ...prevState,
          fk_sequ_competenze: prevState.fk_sequ_competenze.map(
            (el) => {
              if (el.fk_sequ_competenza === row.fk_sequ_competenza) {
                return ({
                  ...el,
                  grading: e.codi_valore,
                });
              } return el;
            },
          ),
        }));
      }
    } else {
      const { name, value } = e.target;
      setStateProfiloRuolo({ ...stateProfiloRuolo, [name]: value });
    }
  };

  // Function collegata al servizio per modificare il profilo di ruolo utilizzando l'id
  async function modificaProfiloRuolo(newState) {
    if (validateModificaProfiloRuolo(stateProfiloRuolo, errors)) {
      SistemaProfessionaleService.modificaProfiloRuolo(
        idProfiloRuolo,
        newState,
      )
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else if (errors.fk_sequ_competenze) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore: associare un grading ad ogni competenza inserita', statusCode: 400,
      }));
    }
  }

  // Function attivata al click salva per l'aggiunta delle competenze cts
  const onConfirmCts = () => {
    setStateProfiloRuolo({
      ...stateProfiloRuolo,
      fk_sequ_competenze: competenzeListSelected,
    });
    validateModificaProfiloRuolo(
      {
        ...stateProfiloRuolo,
        fk_sequ_competenze: competenzeListSelected,
      },
      errors,
    );
    setModalShowCts(false);
  };

  // Funzione attivata al click del chiudi nella modale di aggiungi CTS
  const onCloseCts = () => {
    setStateProfiloRuolo({
      ...stateProfiloProfessionale,
      fk_sequ_competenze: competenzeIniziali,
    });
    setCompetenzeListSelected(competenzeIniziali);
    setModalShowCts(false);
  };

  // Function attivata al click di elimina singola competenza dalla tabella
  const onDeleteCompetenza = (competenza) => {
    setCompetenzeListSelected((list) => list.filter(
      (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
    ));
    setStateProfiloRuolo((prevState) => {
      const newState = {
        ...prevState,
        fk_sequ_competenze: prevState.fk_sequ_competenze.filter(
          (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
        ),
      };
      return newState;
    });
  };

  // Function per gestire la stampa delle informazioni di dettaglio del profilo
  const handlePrint = (stateRuolo, stateProfilo) => {
    // Recupero array delle competenze collegate al profilo
    const competenzeTecnicheProfessionali = stateProfilo.fk_sequ_competenze?.filter((item) => item.codi_tipo_competenza === 'CTP') ?? [];
    const competenzeComportamentali = stateProfilo.fk_sequ_competenze?.filter((item) => item.codi_tipo_competenza === 'CC') ?? [];
    const competenzeTecnicheSpecialistiche = stateRuolo.fk_sequ_competenze?.filter((item) => item.codi_tipo_competenza === 'CTS') ?? [];
    const competenzeCTP = competenzeTecnicheProfessionali.map((el) => ({
      ...el,
      titolo_grading: gradingList.filter((el1) => el1.codi_valore === el.grading && el1.codi_tipo_competenza === 'CTP')[0]?.desc_titolo,
    }));
    const competenzeCC = competenzeComportamentali.map((el) => ({
      ...el,
      titolo_grading: gradingList.filter((el1) => el1.codi_valore === el.grading && el1.codi_tipo_competenza === 'CC')[0]?.desc_titolo,
    }));
    const competenzeCTS = competenzeTecnicheSpecialistiche.map((el) => ({
      ...el,
      titolo_grading: gradingList.filter((el1) => el1.codi_valore === el.grading && el1.codi_tipo_competenza === 'CTS')[0]?.desc_titolo,
    }));
    const generateTableRows = (data) => data.map((item) => `
        <tr>
          <td>${item.desc_codice}</td>
          <td>${item.desc_titolo}</td>
          <td>${item.grading} - ${item.titolo_grading}</td>
        </tr>
    `).join('');

    const windowPrint = window.open(stateRuolo.codice, '', 'width=2000,height=1000');

    windowPrint.document.write(`
      <html>
        <head>
          <title>Profilo di Ruolo ${stateRuolo.desc_codice}</title>
          <style>
            body { font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; margin: 0 20px; }
            .section { margin-bottom: 20px; }
            .label { font-weight: bold; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
            .table-title { font-weight: bold; margin-top: 20px; }
          </style>
        </head>
        <body>
          <h1 style="text-align: center;">Profilo di Ruolo ${stateRuolo.desc_codice}</h1>
          <div class="section">
            <span class="label">Codice:</span> ${stateRuolo.desc_codice}
          </div>
          <div class="section">
            <span class="label">Nome:</span> ${stateRuolo.desc_titolo}
          </div>
          <div class="section">
            <span class="label">Comparto:</span> ${stateProfilo.fk_sequ_comparto_d_comparto.desc_comparto}
          </div>
          <div class="section">
            <span class="label">Area Contrattuale CCNL:</span> ${stateProfilo.fk_sequ_area_contrattuale_d_area_contrattuale.desc_titolo}
          </div>
          <div class="section">
            <span class="label">Dimensione Professionale:</span> ${stateProfilo.dimensione_professionale?.desc_codice ?? '-'} - ${stateProfilo.dimensione_professionale?.desc_titolo ?? '-'}
          </div>
          <div class="section">
            <span class="label">Famiglia Professionale:</span> ${stateProfilo.famiglia_professionale.desc_codice} - ${stateProfilo.famiglia_professionale.desc_titolo}
          </div>
          <div class="section">
            <span class="label">Scopo Organizzativo:</span> ${stateProfilo.desc_scopo}
          </div>
          <div class="section">
            <span class="label">Macro attività caratterizzanti il Profilo Professionale:</span> ${stateProfilo.desc_attivita}
          </div>
          <div class="section">
            <span class="label">Macro attività caratterizzanti il Profilo di Ruolo:</span> ${stateRuolo.desc_attivita}
          </div>
          <div class="table-title">Competenze Tecnico Specialistiche</div>
            <table>
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Competenza</th>
                  <th>Grading</th>
                </tr>
              </thead>
              <tbody>
                ${generateTableRows(competenzeCTS)}
              </tbody>
            </table>
          </div>
          <div class="table-title">Competenze Tecnico Professionali</div>
            <table>
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Competenza</th>
                  <th>Grading</th>
                </tr>
              </thead>
              <tbody>
                ${generateTableRows(competenzeCTP)}
              </tbody>
            </table>
          </div>
          <div>
            <div class="table-title">Competenze Comportamentali</div>
            <table>
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Competenza</th>
                  <th>Grading</th>
                </tr>
              </thead>
              <tbody>
                ${generateTableRows(competenzeCC)}
              </tbody>
            </table>
          </div>
          <br />
        </body>
      </html>
    `);

    windowPrint.document.close();
    setTimeout(() => {
      windowPrint.focus();
      windowPrint.print();
      windowPrint.close();
    }, 1000);
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Profilo di Ruolo"
          showModify={!isModify && controlla('X26') && idAmministrazione === stateProfiloRuolo?.fk_codi_amministrazione}
          functionModify={() => setIsModify(true)}
          showSave={isModify}
          functionSave={() => modificaProfiloRuolo(stateProfiloRuolo)}
          showView={isModify}
          functionView={() => setIsModify(false)}
          showGeneral
          titleGeneral="Vai al profilo professionale"
          variantGeneral="outline-primary"
          functionGeneral={() => history.push(`/area-riservata/profili-professionali/${stateProfiloRuolo.fk_sequ_profilo_professionale}`, { isModify: false })}
        />
        {!isModify && (
          <Col className="col-lg-auto text-end">
            <Button
              style={{ color: 'white' }}
              variant="success"
              onClick={() => handlePrint(stateProfiloRuolo, stateProfiloProfessionale)}
            >
              {' '}
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'print']} />
              {' '}
              STAMPA
              {' '}
            </Button>
          </Col>
        )}
        <FormProfiliRuolo
          stateProfiloRuolo={stateProfiloRuolo}
          stateProfiloProfessionale={stateProfiloProfessionale}
          onChange={onChange}
          isModify={isModify}
          setModalShowCts={setModalShowCts}
          gradingList={gradingList}
          onDeleteCompetenza={onDeleteCompetenza}
          handleTableChange={handleTableChange}
        />
      </Container>
      <ModaleCentroVerticale
        modalTitle="Aggiungi competenze"
        modalBody={(
          <FormSelezioneCompetenze
            tipoCompetenza="CTS"
            competenzeListSelected={competenzeListSelected.map((el) => el.fk_sequ_competenza)}
            setCompetenzeListSelected={setCompetenzeListSelected}
          />
        )}
        buttonType="submit"
        show={modalShowCts}
        onConfirm={() => onConfirmCts()}
        labelConfirm="Salva"
        onClose={() => onCloseCts()}
        size="xl"
      />
    </>
  );
}

export default ProfiloRuoloDettaglio;
