import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Row, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Title from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import FormRichiestaIdentita from '../../../../components/RichiesteIdentita/Form';
import richiestIdentitaService from '../../../../services/richiestaIdentitaService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

function DettaglioRichiestaIdentitaPerUtente() {
  const { idRichiesta } = useParams();
  const [context, setContext] = useContext(Context);
  const [richiestaIdentita, setRichiestaIdentita] = useState({});
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [options, setOptions] = useState([]);
  const [stati, setStati] = useState([]);
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function deleteRichiesta() {
    richiestIdentitaService.deleteRichiesta(idRichiesta)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/richiesta-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function getRichiestaIdentita() {
    const res = await richiestIdentitaService.getRichiestaIdentitaById(idRichiesta);
    setRichiestaIdentita(res);
    if (res.codi_ipa) {
      const dettaglioIpa = await richiestIdentitaService
        .getIpaByCodi(res.codi_ipa);
      setOptions([{ cod_amm: res.codi_ipa, des_amm: dettaglioIpa.des_amm }]);
    }
  }

  function campiCompilati() {
    if (richiestaIdentita.desc_email && richiestaIdentita.codi_ipa
      && richiestaIdentita.desc_nome_responsabile && richiestaIdentita.desc_cognome_responsabile
      && richiestaIdentita.desc_cf_responsabile) {
      return true;
    }
    return false;
  }

  async function getStatiRichieste() {
    try {
      setStati(await richiestIdentitaService.getStati());
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getRichiestaIdentita();
    getStatiRichieste();
  }, []);

  return (
    <Row id="form-richiesta-identita">
      <Row>
        <Title
          title="Dettaglio richiesta di accreditamento"
          subtitle="È possibile visualizzare i dettagli della richiesta di accreditamento"
          showIndietro
          functionIndietro={() => history.push('/area-riservata/richiesta-identita')}
        />
      </Row>
      <FormRichiestaIdentita
        richiestaIdentita={richiestaIdentita}
        disabled
        context={context}
        setContext={setContext}
        campiCompilati={campiCompilati}
        stati={stati}
        options={options}
        setOptions={setOptions}
        isDetail
      />
      {new URLSearchParams(window.location.search).get('elimina')
        ? (
          <>
            <div className="d-flex justify-content-end">
              <Button variant="danger" className="text-uppercase" onClick={handleStateModaleConferma}>
                <FontAwesomeIcon aria-hidden="true" icon="trash" />
                {' '}
                Elimina
              </Button>
            </div>
            <ModaleConferma
              labelBottone="Conferma"
              coloreAzione="primary"
              title="Sei sicuro di procedere?"
              body={'L\'operazione eliminerà la richiesta selezionata'}
              show={openCloseModaleConferma}
              handleClose={handleStateModaleConferma}
              handleOk={deleteRichiesta}
            />
          </>
        )
        : null }
    </Row>
  );
}

export default DettaglioRichiestaIdentitaPerUtente;
