import { Accordion, Container } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { Context } from '../../../../utils/Context';
import { getItem } from '../../../../utils/storage';
import StatisticheService from '../../../../services/statisticheService';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import AccordionTable from '../../../../components/Accordion/Tables';
import matriceProfiliProfessionaliFields from '../fieldsTables/matriceProfiliProfessionaliFields';
import FiltriMatriceProfiliProfessionali from '../filtriTables/filtriMatriceProfiliProfessionali';

function ReportingMatrice() {
  const [context, setContext] = useContext(Context);
  const [statisticheList, setStatisticheList] = useState({});
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [areeContrattualiList, setAreeContrattualiList] = useState([]);
  const [dimensioniProfessionaliList, setDimensioniProfessionaliList] = useState([]);
  const [ambitoRuoloList, setAmbitoRuoloList] = useState([]);
  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;

  // Function chiamata API
  async function getStatistiche(fk_sequ_comparto, type, offset, limit, page, filters) {
    const typeList = type.replaceAll('-', '_');
    try {
      const response = await StatisticheService
        .getStatistiche(fk_sequ_comparto, type, offset, limit, filters);
      setStatisticheList({
        ...statisticheList,
        [typeList]: response.rows,
      });
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filters, offset, limit);
      setFamiglieProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreaContrattuale(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filterSession, offset, limit);
      setAreeContrattualiList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle dimensioni professionali
  async function getDimensioniProfessionali(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getDimensioniProfessionali(filterSession, offset, limit);
      setDimensioniProfessionaliList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista degli ambiti di ruolo
  async function getAmbitiRuolo(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAmbitiRuolo(filterSession, offset, limit);
      setAmbitoRuoloList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per gestire l'esportazione delle tabelle in formato excel
  async function handleExport(exportFunction, fileName, type) {
    try {
      let exportData = await exportFunction();
      if (type === 'application/json;charset=utf-8;') {
        exportData = JSON.stringify(exportData, null, 2);
      }
      const blob = new Blob([exportData], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', fileName);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Errore durante il download del CSV:', error);
    }
  }
  return (
    <Container className="p-0">
      <TitlePage
        title="Statistiche Matrice dei Profili Professionali"
        subtitle="In questa sezione è possibile visionare e scaricare in diversi
        formati tutte le statistiche disponibili."
      />
      <Accordion>
        <AccordionTable
          eventKey="16"
          onClick={() => {
            getStatistiche(sequ_k_comparto, 'matrice-profili-professionali-profili-ruolo', 0, 10, 1, {});
            getFamiglieProfessionali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
            getDimensioniProfessionali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
            getAreaContrattuale(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
            getAmbitiRuolo(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
          }}
          title="Matrice dei Profili Professionali"
          data={statisticheList.matrice_profili_professionali_profili_ruolo}
          fieldsData={matriceProfiliProfessionaliFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          filtriBody={(
            <FiltriMatriceProfiliProfessionali
              filtri={filtri}
              setFiltri={setFiltri}
              sequ_k_comparto={sequ_k_comparto}
              areeContrattualiList={areeContrattualiList}
              famiglieProfessionaliList={famiglieProfessionaliList}
              dimensioniProfessionaliList={dimensioniProfessionaliList}
              ambitoRuoloList={ambitoRuoloList}
              getMatriceProfiliProfessionali={getStatistiche}
            />
          )}
          pagination={pagination}
          keyField="matrice_profili_professionali_profili_ruolo_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'matrice-profili-professionali-profili-ruolo', 'csv', null, null, filtri),
            'matrice-profili-professionali-profili-ruolo.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'matrice-profili-professionali-profili-ruolo', 'json', null, null, filtri),
            'matrice-profili-professionali-profili-ruolo.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={sequ_k_comparto}
          typeStatistica="matrice-profili-professionali-profili-ruolo"
          isExportable
        />
      </Accordion>
    </Container>
  );
}

export default ReportingMatrice;
