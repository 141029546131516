import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../components/Breadcrumb';
import './index.css';
import SimpleButton from '../../components/SimpleButton';
import spidIcoCircleBB from '../../images/spid-ico-circle-bb.png';

function AreaAmministrazioni() {
  return (
    <Container className="justify-content-center col-md-10">
      <Row xs={12}>
        <BreadcrumbComponent listItem={[{ name: 'Area Amministrazioni', pathname: '/area-amministrazioni', active: true }]} />
      </Row>
      <Row className="justify-content-center">
        <Col className="my-3">
          <h1>Area Amministrazioni</h1>
        </Col>
      </Row>
      <Row>
        <Container className="p-0">
          <Container className="p-0 text-format">
            Per accedere al Portale e utilizzare le relative funzionalità, occorre che ogni
            Amministrazione aderisca attraverso un processo di &quot;accreditamento&quot;
            con il supporto del Dipartimento della Funzione Pubblica della Presidenza del Consiglio
            dei Ministri.
          </Container>
          <Container className="p-0 text-format">
            Prima della registrazione ciascuna Amministrazione deve individuare e nominare un
            Responsabile per il Portale RiVa.
          </Container>
          <Container className="p-0 text-format">
            Accedi per eseguire la procedura di accreditamento.
          </Container>
        </Container>
      </Row>
      <Row className="justify-content-center text-align mt-5">
        <Container className="p-0 text-format">
          <strong>Accedi all&apos;Area riservata dedicata alle Amministrazioni</strong>
        </Container>
      </Row>
      <Row className="justify-content-center text-align mt-3">
        <SimpleButton
          label="Accedi"
          alt="Logo Account"
          icon={spidIcoCircleBB}
          href="/pagina-login"
          bgColor="#06c"
          width="auto"
        />
        <a href="/manuale-accreditamento.pdf" target="_blank" className="mt-5">Scarica il manuale utente</a>
      </Row>
    </Container>
  );
}

export default AreaAmministrazioni;
