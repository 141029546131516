import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import { getItem } from '../../../../utils/storage';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import FormDimensioneProfessionale from '../FormDimensioneProfessionale';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormSelezioneCompetenze from '../../../../components/Form/SelezioneCompetenze';
import history from '../../../../utils/history';
import checkCompsModified from '../../../../utils/checkCompsModified';
import { controlla } from '../../../../utils/verificaPermessi';

function DimensioneProfessionaleDettaglio() {
  const [context, setContext] = useContext(Context);
  const { idDimensioneProfessionale } = useParams();
  const { state } = useLocation();
  const [stateDimensioneProfessionale, setStateDimensioneProfessionale] = useState({});
  const [compsOriginal, setCompsOriginal] = useState({});
  const [competenzeListSelected, setCompetenzeListSelected] = useState([]);
  const [gradingList, setGradingList] = useState([]);
  const [isModify, setIsModify] = useState(false);
  const [modalShowCtp, setModalShowCtp] = useState(false);
  const [modalShowCc, setModalShowCc] = useState(false);
  const [errors, setErrors] = useState({});
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idAmministrazione } = JSON.parse(getItem('identita'));

  // Validazione per la creazione di una dimensione professionale
  const validateDimensioneProfessionale = (stato, error) => {
    const temp = { ...error };
    temp.desc_titolo = stato.desc_titolo === '';
    temp.fk_sequ_competenze = stato.fk_sequ_competenze.some((el) => el.grading === 0);
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // function collegata al servizio di get di una particolare dimensione professionale
  async function getDimensioneProfessionaleById(id) {
    try {
      const response = await SistemaProfessionaleService.getDimensioneProfessionaleById(id);
      const listCompetenzeMap = response.d_rel_dimensione_prof_comps.map(
        (el) => ({
          ...el.competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
        }),
      );
      const responseMap = {
        desc_titolo: response.desc_titolo,
        fk_sequ_comparto: response.fk_sequ_comparto,
        desc_codice: response.desc_codice,
        fk_sequ_competenze: listCompetenzeMap,
        fk_codi_amministrazione: response.fk_codi_amministrazione,
      };
      setCompsOriginal(responseMap);
      setStateDimensioneProfessionale(responseMap);
      setCompetenzeListSelected(listCompetenzeMap);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    if (idDimensioneProfessionale) {
      getDimensioneProfessionaleById(idDimensioneProfessionale);
      setIsModify(state.isModify ?? false);
    } else {
      setStateDimensioneProfessionale({
        desc_titolo: '',
        fk_sequ_comparto: sequ_k_comparto,
        fk_sequ_competenze: [],
      });
    }
    // IMPOSTATO LIMIT 1000
    getGrading(0, 1000, {});
  }, [idDimensioneProfessionale]);

  // function per effettuare il refresh della pagina
  function refresh() {
    getDimensioneProfessionaleById(idDimensioneProfessionale);
    setIsModify(false);
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  }

  // function collegata al servizio di creazione di una nuova dimensione professionale
  async function nuovaDimensioneProfessionale(newState) {
    if (validateDimensioneProfessionale(stateDimensioneProfessionale, errors)) {
      SistemaProfessionaleService.nuovaDimensioneProfessionale(newState)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          history.push('/area-riservata/dimensioni-professionali');
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else if (errors.fk_sequ_competenze) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore: associare un grading ad ogni competenza agginuta', statusCode: 400,
      }));
    }
  }

  // function collegata al servizio di modifica di una dimensione professionale
  async function modificaDimensioneProfessionale(newState) {
    if (validateDimensioneProfessionale(newState, errors)) {
      const newDimensione = checkCompsModified(newState, compsOriginal.fk_sequ_competenze);
      SistemaProfessionaleService.modificaDimensioneProfessionale(
        idDimensioneProfessionale,
        newDimensione,
      )
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else if (errors.fk_sequ_competenze) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore: associare un grading ad ogni competenza inserita', statusCode: 400,
      }));
    }
  }

  // function per modificare lo stato della dimensione professionale che l'utente sta modificando.
  const onChange = (e, config, row) => {
    if (config) {
      setCompetenzeListSelected((prevState) => prevState.map((comp) => {
        if (comp.fk_sequ_competenza === row.fk_sequ_competenza) {
          return ({
            ...comp,
            grading: e.codi_valore,
          });
        }
        return comp;
      }));
      setStateDimensioneProfessionale((prevState) => ({
        ...prevState,
        fk_sequ_competenze: prevState.fk_sequ_competenze.map(
          (el) => {
            if (el.fk_sequ_competenza === row.fk_sequ_competenza) {
              return ({
                ...el,
                grading: e.codi_valore,
              });
            } return el;
          },
        ),
      }));
    } else {
      const { name, value } = e.target;
      setStateDimensioneProfessionale({ ...stateDimensioneProfessionale, [name]: value });
      validateDimensioneProfessionale({ ...stateDimensioneProfessionale, [name]: value }, errors);
    }
  };

  // Funzione attivata al click del conferma nella modale di aggiungi CTP
  const onConfirmCtp = () => {
    const newState = {
      ...stateDimensioneProfessionale,
      fk_sequ_competenze: competenzeListSelected,
    };
    setStateDimensioneProfessionale(newState);
    validateDimensioneProfessionale(newState, errors);
    setModalShowCtp(false);
  };

  // Funzione attivata al click del chiudi nella modale di aggiungi CTP
  const onCloseCtp = () => {
    const initialComps = stateDimensioneProfessionale.fk_sequ_competenze || [];
    setStateDimensioneProfessionale({
      ...stateDimensioneProfessionale,
      fk_sequ_competenze: initialComps,
    });
    setCompetenzeListSelected(initialComps);
    setModalShowCtp(false);
  };

  // Funzione attivata al click del conferma nella modale di aggiungi CC
  const onConfirmCc = () => {
    const newState = {
      ...stateDimensioneProfessionale,
      fk_sequ_competenze: competenzeListSelected,
    };
    setStateDimensioneProfessionale(newState);
    validateDimensioneProfessionale(newState, errors);
    setModalShowCc(false);
  };

  // Funzione attivata al click del chiudi nella modale di aggiungi CC
  const onCloseCc = () => {
    const initialComps = stateDimensioneProfessionale.fk_sequ_competenze || [];
    setStateDimensioneProfessionale({
      ...stateDimensioneProfessionale,
      fk_sequ_competenze: initialComps,
    });
    setCompetenzeListSelected(initialComps);
    setModalShowCc(false);
  };

  // Function attivata al click di elimina singola competenza dalla tabella
  const onDeleteCompetenza = (competenza) => {
    setCompetenzeListSelected((list) => list.filter(
      (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
    ));
    setStateDimensioneProfessionale((prevState) => {
      const newState = {
        ...prevState,
        fk_sequ_competenze: prevState.fk_sequ_competenze.filter(
          (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
        ),
      };
      return newState;
    });
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title={idDimensioneProfessionale ? 'Dimensione Professionale' : 'Nuova Dimensione Professionale'}
          showModify={!isModify
            && idDimensioneProfessionale
            && stateDimensioneProfessionale.fk_codi_amministrazione === idAmministrazione && controlla('X42')}
          functionModify={() => setIsModify(true)}
          showSave={isModify && idDimensioneProfessionale}
          functionSave={() => modificaDimensioneProfessionale(stateDimensioneProfessionale)}
          showView={isModify && idDimensioneProfessionale}
          functionView={() => setIsModify(false)}
          showAdd={!idDimensioneProfessionale}
          functionAdd={() => nuovaDimensioneProfessionale(stateDimensioneProfessionale)}
        />
        <FormDimensioneProfessionale
          stateDimensioneProfessionale={stateDimensioneProfessionale}
          optionsGrading={gradingList}
          onChange={onChange}
          onDeleteCompetenza={onDeleteCompetenza}
          isDisabled={!isModify && idDimensioneProfessionale}
          isNew={!idDimensioneProfessionale}
          setModalShowCtp={setModalShowCtp}
          setModalShowCc={setModalShowCc}
          errors={errors}
        />
      </Container>
      <ModaleCentroVerticale
        modalTitle="Competenze Tecnico Professionali"
        modalBody={(
          <FormSelezioneCompetenze
            tipoCompetenza="CTP"
            competenzeListSelected={competenzeListSelected.map((el) => el.fk_sequ_competenza)}
            setCompetenzeListSelected={setCompetenzeListSelected}
          />
          )}
        buttonType="submit"
        show={modalShowCtp}
        onConfirm={() => onConfirmCtp()}
        labelConfirm="Aggiungi competenze"
        onClose={() => onCloseCtp()}
        size="xl"
      />
      <ModaleCentroVerticale
        modalTitle="Competenze Comportamentali"
        modalBody={(
          <FormSelezioneCompetenze
            tipoCompetenza="CC"
            competenzeListSelected={competenzeListSelected.map((el) => el.fk_sequ_competenza)}
            setCompetenzeListSelected={setCompetenzeListSelected}
          />
          )}
        buttonType="submit"
        show={modalShowCc}
        onConfirm={() => onConfirmCc()}
        labelConfirm="Aggiungi competenze"
        onClose={() => onCloseCc()}
        size="xl"
      />
    </>
  );
}

export default DimensioneProfessionaleDettaglio;
