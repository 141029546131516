import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { controlla } from '../../../utils/verificaPermessi';
import templateService from '../../../services/templateService';
import Pagination from '../../../components/Questionari/Pagination';
import FiltriRicercaQuestionari from '../../../components/Questionari/filtriRicerca';
import CardGroup from '../../../components/Template/CardGroup';

const baseUrl = process.env.REACT_APP_BASE_URL;
const templateUrl = process.env.REACT_APP_QUESTIONARI_URL;

function Template() {
  const [context, setContext] = useContext(Context);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [testoModaleConferma, setTestoModaleConferma] = useState('');
  const [funzioneModaleConferma, setFunzioneModaleConferma] = useState();
  const [idTemplate, setIdTemplate] = useState();
  const [templates, setTemplates] = useState([]);
  const [filtri, setFiltri] = useState({
    titolo: '',
    stato: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  // Function per gestire i filtri
  function onChangeFiltri(e, config) {
    if (config.name) {
      setFiltri({
        ...filtri, [config.name]: e?.valore,
      });
    } else {
      const {
        id, value, checked, type,
      } = e.target;
      setFiltri({
        ...filtri,
        [id]: type === 'checkbox' ? checked : value,
      });
    }
  }

  // function per gestire la modale di conferma
  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  // Function collegata ai servizi di copia link
  function copyLink(template) {
    navigator.clipboard.writeText(`
    ${baseUrl}/${template.isTracciato ? 'area-ospite/' : ''}questionari/${template._id}
    `);
    try {
      navigator.clipboard.writeText(`
    ${baseUrl}/${template.isTracciato ? 'area-ospite/' : ''}questionari/${template._id}
    `);
      setContext(() => ({
        ...context, openConferma: true, testo: 'Il link è stato correttamente copiato',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore', statusCode: 400,
      }));
    }
  }

  // Function attivata al click di mostra template
  function showTemplate(id) {
    history.push(`/area-riservata/template/visualizza-template/${id}`);
  }

  // Function attivata al click di mostra statistiche
  function showStatistiche(id) {
    history.push(`/area-riservata/template/visualizza-statistiche/${id}`);
  }

  // Function attivata al click di modifica template
  async function editTemplate(id) {
    history.push(`/area-riservata/template/gestione-template/${id}`);
  }

  // Function collegata al servizio di get della lista dei template
  async function getTemplates(offset, limit) {
    try {
      const response = await templateService.getTemplates(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setTemplates(response.listaTemplate);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di creazione di un template
  async function createTemplate() {
    try {
      const res = await templateService.createTemplate();
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      editTemplate(res.id);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di eliminazione di un template
  async function deleteTemplate(id) {
    try {
      const res = await templateService.deleteTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getTemplates(0, 5);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di pubblicazione di un template
  async function publishTemplate(id) {
    try {
      const res = await templateService.publishTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getTemplates(0, 5);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di clonazione di un template
  async function cloneTemplate(id) {
    try {
      const res = await templateService.cloneTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      editTemplate(res.id);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di ritiro di un template
  async function retireTemplate(id) {
    try {
      const res = await templateService.retireTemplate(id);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getTemplates(0, 5);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di esportazione di un template
  async function exportTemplate(id, formato) {
    const a = document.createElement('A');
    a.href = `${templateUrl}/template/${id}/esporta?formato=${formato}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // useEffect
  useEffect(() => {
    getTemplates(0, 5);
  }, []);

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Template"
          subtitle="In questa sezione è possibile visualizzare la lista dei template presenti."
          showAdd={controlla('X37')}
          functionAdd={createTemplate}
          showRefresh
          functionRefresh={() => getTemplates(0, 5)}
        />
        <FiltriRicercaQuestionari
          filtri={filtri}
          getElements={getTemplates}
          onChange={onChangeFiltri}
        />
        <CardGroup
          templates={templates}
          functionShow={showTemplate}
          functionEdit={editTemplate}
          functionDelete={deleteTemplate}
          functionPublish={publishTemplate}
          functionClone={cloneTemplate}
          functionRetire={retireTemplate}
          functionExport={exportTemplate}
          functionSeeStats={showStatistiche}
          setIdTemplate={setIdTemplate}
          handleStateModaleConferma={handleStateModaleConferma}
          setFunzioneModaleConferma={setFunzioneModaleConferma}
          setTestoModaleConferma={setTestoModaleConferma}
          copyLink={copyLink}
        />
        {pagination.totalSize !== 0 && (
        <Pagination
          functionShow={getTemplates}
          pagination={pagination}
        />
        )}
      </Container>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione ${testoModaleConferma} il template selezionato`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => {
          funzioneModaleConferma(idTemplate);
          handleStateModaleConferma();
        }}
      />
    </>
  );
}

export default Template;
